.footer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1rem;
  margin-top: 1rem;
}

.first-section {
  width: 100%;
}

.second-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  justify-content: space-between;
}

.logo {
  margin: 1rem 0;
}

.brand-logo {
  height: 2rem;
  margin-right: 1rem;
}

.social-media-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1rem 0;
}

.social-media-icon {
  height: 3rem;
  width: 3rem;
}

.product,
.engage {
  width: 40%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: black;
  font-weight: 400;
}

@media (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .first-section {
    width: 25%;
  }

  .second-section {
    width: 50%;
    text-align: right;
    justify-content: flex-end;
    /* w-50 d-flex text-right justify-content-end */
  }
}

@media (min-width: 1200px) {
  .footer {
    width: 100%;
  }
}
