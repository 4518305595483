.form-group{
    width: 100%;
    margin-top: 1rem;
}
.searchIcon{
    position: relative;
    transform: translate(1.4rem, 2.6rem);
}
.search-bar{
    width: 100%;
    height: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border: none;
    outline: none;
    border-radius: 1.5rem;
    background-color: #EFEEEE;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 1.5rem;
}