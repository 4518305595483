.tab-container {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
}

.tab-content {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0 0.5rem;
  color: rgba(159, 159, 159, 1);
  cursor: pointer;
  transition: all ease 0.1s;
  width: 100%;
  white-space: nowrap;
  /* word-wrap: initial; */
}

.tab-content.active {
  font-weight: 700;
  color: rgba(106, 64, 41, 1);
  border-bottom: 2px solid rgba(106, 64, 41, 1);
}

.menu-list {
  padding: 1rem 1rem;
}

.menu-container {
  padding: 5rem 1rem;
  display: flex;
  flex-direction: row;
  gap: 4rem 1.5rem;
  flex-wrap: wrap;
}

.btn-new-product {
  padding: 1rem 2rem;
  background-color: rgba(106, 64, 41, 1);
  color: white;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  align-self: center;
}

.btn-new-product:hover {
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

@media (max-width: 424px) {
  .menu-list {
    padding: 1rem 2rem;
  }
}

@media (min-width: 768px) {
  .tab-container {
    padding: 0 1.2rem;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
