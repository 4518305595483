.card {
  background-color: #fff;
  border-radius: 5px;
  border-bottom: 10px solid rgba(106, 64, 41, 1);
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  align-items: center;
}

.circle {
  height: 6rem;
  width: 6rem;
  margin-bottom: 1.5rem;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.name {
  font-family: Rubik;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.history {
  margin-top: 1rem;
  font-family: Rubik;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
