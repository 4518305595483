.cta {
  width: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  padding: 2rem 3rem;
  background-color: #fff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
  align-items: center;
}

.btn-promo {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  background: #ffba33;
  color: #6a4029;
  padding: 0 3.5rem;
  height: 3rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.btn-promo:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
}
