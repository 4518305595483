.button-group {
  display: flex;
  flex-direction: column;
}
.btn-cart {
  align-self: center;
  width: 50%;
  min-width: 10rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #6a4029;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  box-shadow:  0px 0px 10px rgba(11, 19, 42, 0.24);
}
.btn-ask {
  align-self: center;
  width: 50%;
  min-width: 10rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #ffba33;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}
.btn-delete {
  align-self: center;
  width: 50%;
  min-width: 10rem;
  height: 4rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: black;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}

@media (max-width: 1024px) {
.btn-cart{
  width: 100%;
}
.btn-ask{
  width: 100%;
}
.btn-delete{
  width: 100%;
}
}