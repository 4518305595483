@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gray-2 {
  background-color: #bcbaba;
}

.section-title {
  font-family: Rubik;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  margin-bottom: 1rem;
}

.section-description {
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: #4f5665;
}

@media (min-width: 1440px) {
  .container {
    max-width: 90%;
  }
}

.Navbar_brand-logo__2CtVk {
  height: 2rem;
  margin-right: 1rem;
}

.Navbar_button-container__sVVn0 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Navbar_btn-signup__3sYPQ {
  background: #ffba33;
  height: 2.4rem;
  padding: 0 1rem;
  border-radius: 3rem;
}

@media (min-width: 768px) {
  /* nav {
    margin: 0 4rem;
  } */

  .Navbar_button-container__sVVn0 {
    display: block;
  }
}

@media (min-width: 992px) {
  nav {
    /* margin: 0 1rem; */
    height: 5rem;
  }
}

.LoggedIn_logged-in__9ATix {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.LoggedIn_image__o3DAH {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  object-position: center;
  object-fit: cover;
}

.LoggedIn_circle__3065M {
  border-radius: 50%;
}

.Footer_footer__28pE7 {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  padding: 1rem;
  margin-top: 1rem;
}

.Footer_first-section__1-IgO {
  width: 100%;
}

.Footer_second-section__1XPzT {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  justify-content: space-between;
}

.Footer_logo__TuCzv {
  margin: 1rem 0;
}

.Footer_brand-logo__3lcmm {
  height: 2rem;
  margin-right: 1rem;
}

.Footer_social-media-container__3k8RA {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1rem 0;
}

.Footer_social-media-icon__2_q34 {
  height: 3rem;
  width: 3rem;
}

.Footer_product__2vGXv,
.Footer_engage__3goUH {
  width: 40%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.Footer_title__3oV0V {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: black;
  font-weight: 400;
}

@media (min-width: 768px) {
  .Footer_footer__28pE7 {
    flex-direction: row;
    justify-content: space-between;
  }

  .Footer_first-section__1-IgO {
    width: 25%;
  }

  .Footer_second-section__1XPzT {
    width: 50%;
    text-align: right;
    justify-content: flex-end;
    /* w-50 d-flex text-right justify-content-end */
  }
}

@media (min-width: 1200px) {
  .Footer_footer__28pE7 {
    width: 100%;
  }
}

.Layout_main__auk_r {
  margin-top: 54px;
}

@media (min-wdith: 768px) {
  .Layout_main__auk_r {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .Layout_main__auk_r {
    margin-top: 5rem;
  }
}

main.container-main {
  display: block;
  width: 100%;
  height: 120%;
  text-decoration: none;
}
.row {
  margin-bottom: 4rem;
}

.img-container {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  margin-bottom: -250px;
  /* background-image: url(./img_auth.png); */
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  padding: 3rem;
  margin: 0;
  margin-bottom: -8rem;
}
.upper-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-arkaffe {
  align-self: center;
  font-size: 1.2rem;
  font-weight: 700;
}
.text-arkaffe:hover {
  text-decoration: none;
  color: inherit;
}
.text-arkaffe img {
  margin-right: 1rem;
}
.btn.btn-signup {
  width: 8rem;
  height: auto;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: #ffba33;
  border-radius: 2rem;
  box-shadow: 0px 6px 20px rgba(255, 186, 51, 0.4);
}

.middle-section {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
}
.middle-section .title {
  margin-top: 5rem;
  margin-bottom: 2rem;
  font-size: 2.2rem;
  font-weight: 800;
  color: #6a4029;
}

.form-group {
  margin-top: 3rem;
}
.form-group input {
  height: 4rem;
  width: 100%;
  padding-left: 1.5rem;
  border: 1px solid #4f5665;
  border-radius: 1rem;
  outline: none;
  font-size: 1.4rem;
  font-weight: 400;
  color: #6a4029;
  vertical-align: center;
}
.form-group label {
  position: absolute;
}
.form-group label span {
  position: relative;
  top: -2rem;

  font-weight: 700;
  font-size: 1.2rem;
  color: #4f5665;
}
.form-group span {
  position: absolute;
}
.form-group span img {
  position: relative;
  top: 1rem;
  right: 3rem;
  cursor: pointer;
}
.form-group input::-webkit-input-placeholder {
  color: #9f9f9f;
}
.form-group input::placeholder {
  color: #9f9f9f;
}
.react-tel-input{
  margin-top: 1rem;
}
.react-tel-input .special-label{
  position: relative;
  font-weight: 700;
  font-size: 1.2rem;
  color: #4f5665;
text-align: start;
}
.react-tel-input .form-control {
  width: 100% ;
  height: 4rem;
  padding-left: 1.5rem;
  border: 1px solid #4f5665;
  border-radius: 1rem;
  outline: none;
  font-size: 1.4rem;
  font-weight: 400;
  color: #6a4029;
}

.link-reset {
  position: relative;
  text-align: start;
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: #4f5665;
}
.link-reset:hover {
  color: #6a4029;
}

.button-group {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.btn-login {
  width: 100%;
  height: 4rem;
  background-color: #ffba33;
  font-size: 1.4rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}
.btn-google {
  width: 100%;
  height: 4rem;
  margin-top: 2rem;
  background-color: white;

  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}
.btn-google img {
  margin-right: 1rem;
}
.bottom-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  width: 100%;
  bottom: 0%;
  padding: 4rem;
}

.popup {
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: 11rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}
.popup-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
}
.button-popup {
  text-align: end;
}
.btn-member {
  width: 15rem;
  height: 3.5rem;
  background-color: #ffba33;

  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}

@media (max-width: 1024px) {
  .middle-section {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .popup-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .popup {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (max-width: 768px) {
  .img-container {
    display: none;
  }
  .login-container {
    width: 100% !important;
  }
  .popup {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .popup-content {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 425px) {
  .container-main {
    min-width: 400px;
  }
  .login-container {
    padding: 1.5rem;
  }
  .popup-content h3 {
    font-size: 1.3rem;
  }
}

.ForgotPassword_container-main__2uQQL {
  display: block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 1rem;
  padding-right: 1rem;
}
.ForgotPassword_main-content__2QS5c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.ForgotPassword_title__3_v3I {
  font-family: "Poppins";
  font-weight: 700;
  color: white;
  margin-top: 7rem;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}
.ForgotPassword_text-desc__15an8 {
  font-family: "Poppins";
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}
.ForgotPassword_form-group-email__x_LLU {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.ForgotPassword_email-input__ukxh_ {
  width: 50%;
  height: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 3rem;
  border: 1px solid #4f5665;
  border-radius: 1rem;
  outline: none;
  font-family: "Poppins";
  font-size: 1.4rem;
  font-weight: 400;
  color: #6a4029;
  vertical-align: center;
}
.ForgotPassword_email-input__ukxh_::-webkit-input-placeholder {
  color: #9f9f9f;
}
.ForgotPassword_email-input__ukxh_::placeholder {
  color: #9f9f9f;
}
.ForgotPassword_btn-send__1HSSP {
  width: 10rem;
  height: 5rem;
  background-color: #ffba33;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  border: none;
  outline: none;
  box-shadow: 0px 6px 20px rgba(255, 186, 51, 0.4);
}
.ForgotPassword_btn-resend__3Juw1 {
  align-self: center;
  width: 20%;
  min-width: 10rem;
  height: 5rem;
  margin-top: 3rem;
  background-color: #6a4029;
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  border-radius: 1rem;
  border: none;
  outline: none;
  box-shadow: 0px 6px 20px rgba(106, 64, 41, 0.46);
}
.ForgotPassword_countdown__2gThb {
  align-self: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 5rem;
  font-family: "Poppins";
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}

@media (max-width: 1024px) {
  .ForgotPassword_form-group-email__x_LLU {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .ForgotPassword_email-input__ukxh_ {
    align-self: center;
    width: 70%;
    height: 5rem;
    margin: 0;
    margin-bottom: 2rem;
  }
  .ForgotPassword_btn-send__1HSSP {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
  }
  .ForgotPassword_btn-resend__3Juw1 {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
    margin-top: 1rem;
  }
}

@media (max-width: 485px) {
  .ForgotPassword_container-main__2uQQL {
    height: 85vh;
    min-width: 395px;
  }
  .ForgotPassword_title__3_v3I {
    font-size: 2rem;
  }
  .ForgotPassword_text-desc__15an8 {
    font-size: 1rem;
  }
  .ForgotPassword_form-group-email__x_LLU {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .ForgotPassword_email-input__ukxh_ {
    align-self: center;
    width: 95%;
    height: 4rem;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.15rem;
  }
  .ForgotPassword_btn-send__1HSSP {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
  }
  .ForgotPassword_btn-resend__3Juw1 {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
    margin-top: 1rem;
  }
}

.Hero_hero__fcDiW {
  height: 32rem;
  padding: 1.5rem;
  background-position: center;
  background-repeat: cover;
  background-size: cover;
  position: relative;
}

.Hero_hero-text__2DmiA {
  padding: 3rem 0;
  color: white;
}

.Hero_hero-title__1dpam {
  font-family: Rubik;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.Hero_hero-description__3IsAq {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  margin: 1rem 0;
}

.Hero_cta__1JDWd {
  background: #ffba33;
  color: #6a4029;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  padding: 0 3.5rem;
  height: 3rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
}

@media (min-width: 768px) {
  .Hero_hero__fcDiW {
    height: 40rem;
  }

  .Hero_hero-title__1dpam {
    font-size: 3rem;
    width: 50%;
  }

  .Hero_hero-description__3IsAq {
    width: 50%;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    margin: 3rem 0;
  }
}

@media (min-width: 992px) {
  .Hero_hero__fcDiW {
    height: 38rem;
  }
}

.Stats_stats__2tDKX {
  padding: 1rem;
  width: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding: 1rem;
  -webkit-transform: translatey(-50%);
          transform: translatey(-50%);
  background-color: #fff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
}

.Stats_circle__2hw_H {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #ffba33;
  margin-right: 1rem;
}

.Stats_circle-icon__2R_eH {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Stats_stat-group__BfO6L {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Stats_number__1nXuc {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
}

.Stats_text__J2bDQ {
  font-size: 0.8rem;
  color: #4f5665;

  font-style: normal;
  font-weight: 400;

  text-align: left;
}

@media (max-width: 374px) {
  .Stats_stats__2tDKX {
    flex-direction: column;
    align-items: center;
    width: 70%;
  }
}

@media (min-width: 375px) {
  .Stats_stats__2tDKX {
    width: 90%;
    padding: 1rem;
  }
}

@media (min-width: 768px) {
  .Stats_stats__2tDKX {
    padding: 2rem 3rem;
  }

  .Stats_number__1nXuc {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
  }

  .Stats_text__J2bDQ {
    font-size: 1.3rem;
    color: #4f5665;

    font-style: normal;
    font-weight: 400;

    text-align: left;
  }
}

@media (min-width: 992px) {
  .Stats_stats__2tDKX {
    padding: 3rem 5rem;
  }
}

@media (min-width: 1200px) {
  .Stats_stats__2tDKX {
    padding: 3rem 6rem;
  }
  .Stats_circle__2hw_H {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: #ffba33;
    margin-right: 1rem;
  }

  .Stats_circle-icon__2R_eH {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@media (min-width: 1440px) {
  .Stats_stats__2tDKX {
    padding: 3rem 10rem;
  }
}

.Featured_featured__2ZkgC {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.Featured_img-illustration__3FYyZ {
  object-fit: cover;
  width: 100%;
  object-position: center;
}

.Featured_perks__3RweB {
  font-family: Rubik;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #4f5665;
}

.Featured_checklist__1Agiw {
  margin-right: 1rem;
}

@media (min-width: 768px) {
  /* .featured {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  } */
}

@media (min-width: 992px) {
  .Featured_illustration__19diu,
  .Featured_perks-container__MemJp {
    padding: 2rem;
  }

  .Featured_featured__2ZkgC {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.Pricing_pricing__1cvtl {
  /* background-color: #fff; */

  padding: 2rem 0;
}

.Pricing_pricing__1cvtl ::-webkit-scrollbar {
  display: none;
}

/* .image-container {
  
  border-radius: 50%;
} */

.Pricing_pricing-container__3g8JE {
  overflow-x: scroll;
  text-align: center;
}

.Pricing_scrollable__2H9-K {
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  display: inline-flex;
  flex-wrap: nowrap;
}

.Pricing_pricing-item__Z0FD8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #dddddd;
  padding: 3rem 3rem;
  border-radius: 10px;
  background-color: #fff;
  min-width: 18rem;
  width: 18rem;
  margin: 0 1rem;
  transition: all ease-in-out 0.5s;
  cursor: pointer;
}

.Pricing_pricing-item__Z0FD8:hover {
  border-color: #6a4029;
}

.Pricing_item-image__1VQ5q {
  height: 6rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.Pricing_title__2Ge2U {
  color: #0b132a;
  font-family: Rubik;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}

.Pricing_addons__3WE83 {
  font-family: Rubik;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #4f5665;
}

.Pricing_checklist__2Shxr {
  margin-right: 1rem;
}

.Pricing_price__20fu7 {
  font-family: Rubik;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #0b132a;
}

.Pricing_btn-order__nsUMD {
  border-radius: 50px;

  padding: 0.6rem 2rem;
  border: 2px solid #ffba33;
}

.Pricing_pricing-item__Z0FD8:hover > .Pricing_btn-order__nsUMD {
  background: #ffba33;
}

.Location_map__2TVuU {
  margin-top: 8rem;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.Partner_partner-container__1xRSF {
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
}

.Partner_image__2mjJA {
  object-fit: contain;
  width: 100%;
  height: 3rem;
}

@media (min-width: 768px) {
  .Partner_partner-container__1xRSF {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .Partner_image__2mjJA {
    height: 4rem;
  }
}

.TestimonialItem_item__3Tl6k {
  border: 3px solid #dddddd;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  transition: all ease-in-out 0.5s;
  min-height: 11rem;
}

.TestimonialItem_item__3Tl6k:hover {
  border: 3px solid #6a4029;
}

.TestimonialItem_head__Ad__b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TestimonialItem_avatar__2SbmP {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.TestimonialItem_name__5EYBI,
.TestimonialItem_address__2tiRB {
  margin: 0;
}

.TestimonialItem_name__5EYBI {
  font-family: Rubik;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
}

.TestimonialItem_address__2tiRB {
  color: #4f5665;
  font-family: Rubik;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.TestimonialItem_rating__NhqaJ {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.TestimonialItem_content__1HZ-I {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  margin-top: 1.5rem;
}

.TestimonialList_testimonial-list__2C-yG {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  overflow-x: scroll;
}

.TestimonialList_testimonial__1yjFz ::-webkit-scrollbar {
  display: none;
}

.TestimonialList_wrapper__DEr61 {
  min-width: 100%;
}

.TestimonialList_indicator-container__3tYUn {
  display: inline-flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.TestimonialList_indicator__3gSMz {
  display: block;
  min-width: 10px;
  width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: #dde0e4;
  cursor: pointer;
}

.TestimonialList_indicator__3gSMz.TestimonialList_active__2frK0 {
  width: 2rem;
  border-radius: 10px;
  background-color: #6a4029;
}

.TestimonialList_arrow-container__1fcRP {
  display: inline-flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.TestimonialList_circle__3XTyP {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 2px solid #6a4029;
  transition: all ease-in-out 0.3s;
}

.TestimonialList_circle__3XTyP.TestimonialList_active__2frK0,
.TestimonialList_circle__3XTyP:hover {
  background-color: #6a4029;
}

.TestimonialList_circle__3XTyP:hover {
  cursor: pointer;
}

.TestimonialList_arrow__Z5H8z {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  fill: #6a4029;
}

.TestimonialList_circle__3XTyP:hover > .TestimonialList_arrow__Z5H8z {
  fill: #fff;
}

.TestimonialList_rotate-180__2n5e5 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media (min-width: 768px) {
  .TestimonialList_wrapper__DEr61 {
    min-width: 50%;
  }
}

@media (min-width: 992px) {
  .TestimonialList_wrapper__DEr61 {
    min-width: 33.3%;
  }
}

@media (min-width: 1200px) {
  .TestimonialList_wrapper__DEr61 {
    min-width: 27.5%;
  }
}

.Cta_cta__3EKVt {
  width: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  padding: 2rem 3rem;
  background-color: #fff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
  align-items: center;
}

.Cta_btn-promo__1-0FO {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  background: #ffba33;
  color: #6a4029;
  padding: 0 3.5rem;
  height: 3rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.Cta_btn-promo__1-0FO:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
}

.EditPencil_edit__2xnDz {
  /* position: absolute;
  top: 1rem;
  right: 1rem; */
  position: relative;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  background-color: rgba(106, 64, 41, 1);
  cursor: pointer;
  -webkit-animation: normal ease-in-out 0.5s;
          animation: normal ease-in-out 0.5s;
}

.EditPencil_edit__2xnDz:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.EditPencil_pencil__1O9b6 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.PromoItem_item__2nIWg {
  position: absolute;
  top: 0;
  background-color: #ffcb65;
  width: 15rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
  opacity: 1;
  -webkit-transform: scale(0.85) translateX(80px);
          transform: scale(0.85) translateX(80px);
  transition: all ease-in-out 0.5s;
}

.PromoItem_content__2Ksu3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.PromoItem_title__2sOYw,
.PromoItem_discount__298qJ {
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
}

.PromoItem_discount__298qJ {
  font-size: 1.15rem;
}

.PromoItem_description__2_iZI {
  font-family: Poppins;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
}

.PromoItem_image__qiovK {
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  object-position: center;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.PromoItem_coupon__3VvPV {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;
  border-top: 1px dashed black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PromoItem_coupon-text__3sq_C {
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.PromoItem_code__2IVJ0 {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin: 0.4rem 0;
}

.PromoItem_expirated__3hAYa {
  font-family: Poppins;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
}

.PromoItem_item__2nIWg.PromoItem_active__2xnKL {
  opacity: 1;
  z-index: 1;
  -webkit-transform: scale(1) translateX(0);
          transform: scale(1) translateX(0);
}

.PromoItem_item__2nIWg.PromoItem_next__iCLbb {
  opacity: 1;
  margin-right: -1;
  z-index: 0;
  background-color: black;
  color: white;
}

.PromoItem_item__2nIWg.PromoItem_prev__pBc-N {
  opacity: 0;
  z-index: 0;
  -webkit-transform: scale(1.2) translateX(100px);
          transform: scale(1.2) translateX(100px);
}

.PromoItem_edit__1yjsf {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.Promo_promo__3UwIp {
  display: flex;
  flex-direction: column;
  position: relative;
}

.Promo_promo-list__KmVDI {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 25rem;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

.Promo_btn-coupon__1xp90,
.Promo_add-new-promo__3D9XI {
  margin: 1rem 2rem;
  padding: 1rem 2rem;
  background-color: rgba(106, 64, 41, 1);
  color: white;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
}

.Promo_add-new-promo__3D9XI {
  padding: 1.5rem 2rem;
  background-color: rgba(255, 186, 51, 1);
  color: rgba(106, 64, 41, 1);
  font-family: Poppins;
  font-size: 1.2rem;
}

.Promo_btn-coupon__1xp90:hover,
.Promo_add-new-promo__3D9XI:hover {
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.Promo_toc__3o1Zm {
  margin-top: 3rem;
  font-family: Rubik;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.Promo_toc-content__1JFSl {
  font-family: Rubik;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

@media (min-width: 768px) {
  .Promo_promo-list__KmVDI {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 25rem;
    padding: 1rem;
  }
}

.MenuItem_menu-item__gzRSb {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 45%;
  width: 45%;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 1rem 0.5rem;
  position: relative;
  cursor: pointer;
  transition: all ease 0.5s;
}

.MenuItem_menu-item__gzRSb:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.MenuItem_image__1lxdc {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 40%;
  margin: 1rem 0;
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  box-shadow: 0 12px 10px rgba(0, 0, 0, 0.4);
}

.MenuItem_menu-item__gzRSb:hover > .MenuItem_image__1lxdc {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
}

.MenuItem_menu-name__3TeTd {
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  margin-top: 2.5rem;
}

.MenuItem_price__3bY74 {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.MenuItem_edit__2Agtc {
  position: absolute;
  bottom: 0rem;
  right: 0rem;

  -webkit-transform: scale(0.9) translateX(20%) translateY(20%);

          transform: scale(0.9) translateX(20%) translateY(20%);
}

@media (max-width: 424px) {
  .MenuItem_menu-item__gzRSb {
    min-width: 100%;
    width: 100%;
    padding: 1rem 0.5rem;
  }
}

@media (min-width: 768px) {
  .MenuItem_menu-item__gzRSb {
    min-width: 30%;
    width: 30%;
    padding: 1rem 0.5rem;
  }
}

@media (min-width: 1200px) {
  .MenuItem_menu-item__gzRSb {
    min-width: 22%;
    width: 22%;
    padding: 1rem 0.5rem;
  }
}

.MenuList_tab-container__23QgC {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
}

.MenuList_tab-content__HV4my {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0 0.5rem;
  color: rgba(159, 159, 159, 1);
  cursor: pointer;
  transition: all ease 0.1s;
  width: 100%;
  white-space: nowrap;
  /* word-wrap: initial; */
}

.MenuList_tab-content__HV4my.MenuList_active__1y8Ec {
  font-weight: 700;
  color: rgba(106, 64, 41, 1);
  border-bottom: 2px solid rgba(106, 64, 41, 1);
}

.MenuList_menu-list__2VvKR {
  padding: 1rem 1rem;
}

.MenuList_menu-container__2KxTn {
  padding: 5rem 1rem;
  display: flex;
  flex-direction: row;
  grid-gap: 4rem 1.5rem;
  gap: 4rem 1.5rem;
  flex-wrap: wrap;
}

.MenuList_btn-new-product__ZkuRz {
  padding: 1rem 2rem;
  background-color: rgba(106, 64, 41, 1);
  color: white;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  align-self: center;
}

.MenuList_btn-new-product__ZkuRz:hover {
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

@media (max-width: 424px) {
  .MenuList_menu-list__2VvKR {
    padding: 1rem 2rem;
  }
}

@media (min-width: 768px) {
  .MenuList_tab-container__23QgC {
    padding: 0 1.2rem;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

.Product_product__2kK__ {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Product_promo-container__10ypn {
  width: 100%;
}

.Product_menu__34WTF {
  width: 100%;
}

@media (min-width: 992px) {
  .Product_product__2kK__ {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 7rem;
  }

  .Product_promo-container__10ypn {
    width: 40%;
  }
  .Product_menu__34WTF {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .Product_promo-container__10ypn {
    width: 33%;
  }
  .Product_menu__34WTF {
    width: 67%;
  }
}

.ProductDetail_main-container__3jrhE {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
  background-color: #ededed;
}
.ProductDetail_left-container__39QDN {
  width: 40%;
  padding-left: 2rem;
  padding-right: 2rem;
}
.ProductDetail_right-container__344MW {
  width: 60%;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 768px) {
  .ProductDetail_main-container__3jrhE {
    flex-direction: column;
  }
  .ProductDetail_left-container__39QDN {
    width: 100%;
  }
  .ProductDetail_right-container__344MW {
    width: 100%;
  }
}
@media (max-width: 675px) {
  .ProductDetail_main-container__3jrhE {
    min-width: 425px;
    padding: 1rem;
  }
  .ProductDetail_left-container__39QDN {
    padding: 0;
  }
  .ProductDetail_right-container__344MW {
    padding: 0;
  }
}

.BreadCrumb_breadcrumb__3cOUp{
    font-size: 400;
    color: #4F5665;;
}
.ProductInfo_productinfo-container__3x4Lt{
display: flex;
flex-direction: column;
justify-content: center;
margin-top: 4rem;
margin-bottom: 2rem;
}
.ProductInfo_img-product__OFFQC{
    align-self: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin-bottom: 2rem;
    -webkit-filter: drop-shadow(0px 40px 40px rgba(0, 0, 0, 0.07));
            filter: drop-shadow(0px 40px 40px rgba(0, 0, 0, 0.07));
}
.ProductInfo_title-product__1tyZR{
    align-self: center;
    text-align: center;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
    font-weight: 900;
}
.ProductInfo_price-product__1pEFu{
    align-self: center;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
}
@media (max-width: 1024px) {
    .ProductInfo_img-product__OFFQC{
        width: 200px;
        height: 200px;
    }
    .ProductInfo_title-product__1tyZR{
        font-size: 2.5rem;
    }
    .ProductInfo_price-product__1pEFu{
        font-size: 1.5rem;
    }
  }
.ButtonGroup_button-group__12UJ6 {
  display: flex;
  flex-direction: column;
}
.ButtonGroup_btn-cart__3dIrp {
  align-self: center;
  width: 50%;
  min-width: 10rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #6a4029;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  box-shadow:  0px 0px 10px rgba(11, 19, 42, 0.24);
}
.ButtonGroup_btn-ask__27Xyf {
  align-self: center;
  width: 50%;
  min-width: 10rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #ffba33;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}
.ButtonGroup_btn-delete__1B9zV {
  align-self: center;
  width: 50%;
  min-width: 10rem;
  height: 4rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: black;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}

@media (max-width: 1024px) {
.ButtonGroup_btn-cart__3dIrp{
  width: 100%;
}
.ButtonGroup_btn-ask__27Xyf{
  width: 100%;
}
.ButtonGroup_btn-delete__1B9zV{
  width: 100%;
}
}
.DescriptionCard_desc-container__2tcYB {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  padding: 4rem;
  background-color: white;
  border-radius: 2rem;
}
.DescriptionCard_delivery-text__2oOx3 {
  display: inline-flex;
}
.DescriptionCard_text-default__R3Dpy {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #6a4029;
}
.DescriptionCard_text-props__3OZfB {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
}
.DescriptionCard_desc-product__HbBHc {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #6a4029;
}
.DescriptionCard_text-size__10v_P {
  text-align: center;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: black;
}
.DescriptionCard_btn-group__3LBsG {
  align-self: center;
  text-align: center;
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}
.DescriptionCard_btn-r__eA8Ln {
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #f4f4f8;
  border: none;
  outline: none;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #9f9f9f;
}
.DescriptionCard_btn-r__eA8Ln:hover {
  background-color: #ffba33;
  color: black;
}
.DescriptionCard_btn-l__25Was {
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #f4f4f8;
  border: none;
  outline: none;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #9f9f9f;
}
.DescriptionCard_btn-l__25Was:hover {
  background-color: #ffba33;
  color: black;
}
.DescriptionCard_btn-xl__3MukR {
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #f4f4f8;
  border: none;
  outline: none;
  border-radius: 500px;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #9f9f9f;
}
.DescriptionCard_btn-xl__3MukR:hover {
  background-color: #ffba33;
  color: black;
}
.DescriptionCard_btn-xl__3MukR:checked {
  background-color: #ffba33;
  color: black;
}
@media (max-width: 1024px) {
  .DescriptionCard_desc-container__2tcYB {
    padding: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .DescriptionCard_text-size__10v_P {
    text-align: center;
    margin-top: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: black;
  }

  .DescriptionCard_btn-r__eA8Ln {
    width: 3rem;
    height: 3rem;
    margin: 0;
    font-size: 1.4rem;
  }

  .DescriptionCard_btn-l__25Was {
    width: 3rem;
    height: 3rem;
    margin: 0;
    font-size: 1.4rem;
  }
  .DescriptionCard_btn-xl__3MukR {
    width: 3rem;
    height: 3rem;
    margin: 0;
    font-size: 1.4rem;
  }
}

.DeliveryMethod_methods-container__1f3sD{
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
}
.DeliveryMethod_text-methods__3NoMJ{
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    margin-bottom: 2rem;
}
.DeliveryMethod_btn-group__1Bghx{
    align-self: center;
    text-align: center;
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 2rem;
}
.DeliveryMethod_btn-dinein__3aWz1{
    width: 10rem;
    height: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #F4F4F8;
    border: none;
    outline: none;
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #9F9F9F;
}
.DeliveryMethod_btn-dinein__3aWz1:hover{
    background-color: #FFBA33;
    color: black;
}
.DeliveryMethod_btn-delivery__PhES-{
    width: 10rem;
    height: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #F4F4F8;
    border: none;
    outline: none;
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #9F9F9F;
}
.DeliveryMethod_btn-delivery__PhES-:hover{
    background-color: #FFBA33;
    color: black;
}
.DeliveryMethod_btn-pickup__1Hm03{
    width: 10rem;
    height: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #F4F4F8;
    border: none;
    outline: none;
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #9F9F9F;
}
.DeliveryMethod_btn-pickup__1Hm03:hover{
    background-color: #FFBA33;
    color: black;
}
.DeliveryMethod_label-input__1DJVO{
    font-family: "Poppins",sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    margin-right: 1rem;
}
.DeliveryMethod_time__3Z7Ha{
    width: 150px;
    height: 3rem;
    border: none;
    outline: none;
    border-bottom: 2px solid #9F9F9F;
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    color: #6A4029;
}
small{
    margin-top: 1rem;
    margin-bottom: 5rem;
    font-family: "Poppins",sans-serif;
    font-style: italic;
    font-size: 1rem;
    font-weight: 400;
    color: #9F9F9F;
}
.Modal_container__2e6JZ {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  max-height: 50vh;
  height: 10rem;
  min-height: 20vh;
  min-width: 80vw;
  width: 14rem;
  z-index: 1032;
  padding: 2rem 1.5rem;
  text-align: center;
  border-radius: 10px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Modal_btn-container__3p58_ {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Modal_question__1EBZv {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.Modal_btn-left__1FqVA {
  padding: 0.5rem 2rem;
  border-radius: 10px;
  border: 2px solid rgba(106, 64, 41, 1);
  color: rgba(106, 64, 41, 1);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

.Modal_btn-right__2m_Xv {
  padding: 0.5rem 2rem;
  border-radius: 10px;
  background-color: rgba(106, 64, 41, 1);
  border: 2px solid rgba(106, 64, 41, 1);
  color: white;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

@media (max-width: 320px) {
  .Modal_container__2e6JZ {
    min-width: 90vw;
  }

  .Modal_question__1EBZv {
    font-size: 1.2rem;
  }

  .Modal_btn-left__1FqVA {
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
  }

  .Modal_btn-right__2m_Xv {
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
  }
}
@media (min-width: 768px) {
  .Modal_container__2e6JZ {
    max-height: 50vh;
    height: 10rem;
    min-height: 20vh;
    min-width: 50vw;
    max-width: 20rem;
    /* z-index: 5; */
    padding: 1.3rem 2rem;
  }
}

@media (min-width: 992px) {
  .Modal_container__2e6JZ {
    max-height: 50vh;
    height: 10rem;
    min-height: 20vh;
    min-width: 30vw;
    width: 100rem;
    /* z-index: 5; */
    padding: 1.3rem 2rem;
  }
}

@media (min-width: 1200px) {
  .Modal_container__2e6JZ {
    padding: 1.5rem 2rem;
  }
}

@media (min-width: 1440px) {
  .Modal_container__2e6JZ {
    padding: 2rem;
  }
}

.Backdrop_backdrop__2jpx1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1031;
}

.HistoryItem_history-item__3opdt {
  position: relative;
  padding: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 1rem;
  min-width: 90%;
  width: 90%;
  border-radius: 10px;
  white-space: nowrap;
  /* overflow: hidden; */
  cursor: pointer;
}

.HistoryItem_history-item__3opdt:hover {
  opacity: 0.8;
}

.HistoryItem_image__B4_Z_ {
  height: 4rem;
  width: 4rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-right: 1rem;
}

.HistoryItem_title__30XYi {
  overflow: hidden;
  font-family: Poppins;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  color: black;
  width: 10rem;
  text-overflow: ellipsis;
}

.HistoryItem_price__2ueIa,
.HistoryItem_description__9QFrU {
  margin: 0;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: rgba(106, 64, 41, 1);
}

.HistoryItem_actions__3RR3u {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  z-index: 2;
  -webkit-transform: translateY(-50%) translateX(25%);
          transform: translateY(-50%) translateX(25%);
  transition: all ease-in-out 0.4s;
  opacity: 0;
}

.HistoryItem_circle__1KBWI,
.HistoryItem_circle-x__dwyXe {
  position: relative;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  background-color: rgba(106, 64, 41, 1);
  border-radius: 50%;
}

.HistoryItem_circle-x__dwyXe {
  background-color: rgba(255, 186, 51, 1);
}

.HistoryItem_action-image__1N_tj {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.HistoryItem_history-item__3opdt:hover > .HistoryItem_actions__3RR3u {
  opacity: 1;
}

@media (min-width: 768px) {
  .HistoryItem_history-item__3opdt {
    min-width: 45%;
    width: 45%;
  }
  .HistoryItem_title__30XYi {
    width: 12rem;
  }
}

@media (min-width: 992px) {
  .HistoryItem_history-item__3opdt {
    min-width: 30%;
    width: 30%;
  }
  .HistoryItem_title__30XYi {
    width: 10rem;
  }
  .HistoryItem_circle__1KBWI,
  .HistoryItem_circle-x__dwyXe {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .HistoryItem_history-item__3opdt {
    min-width: 30%;
    width: 30%;
  }
  .HistoryItem_title__30XYi {
    width: 14rem;
  }
}

@media (min-width: 1440px) {
  .HistoryItem_history-item__3opdt {
    min-width: 23%;
    width: 23%;
  }
  .HistoryItem_title__30XYi {
    width: 10rem;
  }
}

.History_history__1cjgi {
  padding: 4rem 0;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
  grid-gap: 1.8rem;
  gap: 1.8rem;
}

@media (min-width: 768px) {
  .History_history__1cjgi {
    justify-content: flex-start;
  }
}

.Profile_profile__SIL4B {
  padding: 3rem 1rem;
}

.Profile_title__3Sl7C {
  font-family: Rubik;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  color: white;
}

.Profile_body__1oMv3 {
  margin: 1rem 0;
}

.Profile_content__iENs7 {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  /* justify-content: space-between; */
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .Profile_profile__SIL4B {
    padding: 5rem 0;
  }

  .Profile_content__iENs7 {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    margin: 5rem 0;
  }
}

.Avatar_card__1p5LD {
  background-color: #fff;
  border-radius: 5px;
  border-bottom: 10px solid rgba(106, 64, 41, 1);
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  align-items: center;
}

.Avatar_circle__1NTNb {
  height: 6rem;
  width: 6rem;
  margin-bottom: 1.5rem;
}

.Avatar_image__2mBdo {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.Avatar_name__1ed2b {
  font-family: Rubik;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.Avatar_history__2eTpN {
  margin-top: 1rem;
  font-family: Rubik;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.Contact_card__-Cdbl {
  background-color: #fff;
  border-radius: 5px;
  border-bottom: 10px solid rgba(106, 64, 41, 1);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  position: relative;
}

.Contact_edit__3caO8 {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.Contact_title__3Mo0L {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  color: rgba(79, 86, 101, 1);
}

.Contact_content__3ME57 {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.Contact_form-group__3JcJw {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Contact_label__2SUfR {
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  color: rgba(159, 159, 159, 1);
}

.Contact_input__1TUlT {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1.5px solid rgba(159, 159, 159, 1);
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.Contact_input__1TUlT:focus {
  border-bottom: 1.5px solid rgba(0, 0, 0, 1);
}

@media (min-width: 768px) {
  .Contact_content__3ME57 {
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .Contact_form-group__3JcJw {
    width: calc(50% - 1rem);
  }
}

.Detail_card__zP_bs {
  background-color: #fff;
  border-radius: 5px;
  border-bottom: 10px solid rgba(106, 64, 41, 1);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  position: relative;
}

.Detail_edit__13nDC {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.Detail_title__jFdWG {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  color: rgba(79, 86, 101, 1);
}

.Detail_content__26AJp {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Detail_content-left___3no7,
.Detail_content-right__2Q5B7 {
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.Detail_form-group__3RKLQ,
.Detail_form-group-right__17XG0 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Detail_label__1p9LA {
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  color: rgba(159, 159, 159, 1);
}

.Detail_input__1RcXO {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1.5px solid rgba(159, 159, 159, 1);
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.Detail_input__1RcXO:focus {
  border-bottom: 1.5px solid rgba(0, 0, 0, 1);
}

@media (min-width: 768px) {
  .Detail_content__26AJp {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
  }

  .Detail_content-left___3no7 {
    width: calc(70% - 1rem);
    grid-gap: 2rem;
    gap: 2rem;
  }

  .Detail_content-right__2Q5B7 {
    width: 30%;
    justify-content: flex-start;
  }
}

.Actions_actions__3rm8M {
  display: flex;
  flex-direction: column;
}

.Actions_title__owBJ_ {
  color: white;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.Actions_btn-save__1NDd5 {
  background: rgba(106, 64, 41, 1);
  color: white;
  padding: 0.8rem;
  border-radius: 15px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1rem;
}

.Actions_btn-save__1NDd5:hover {
  color: whitesmoke;
}

.Actions_btn-cancel__1vhu0 {
  border-radius: 15px;
  padding: 0.8rem 0;
  background: rgba(255, 186, 51, 1);
  color: rgba(106, 64, 41, 1);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2rem;
}

.Actions_btn-edit-password__9B3pB,
.Actions_btn-logout__2VG-x {
  border-radius: 15px;
  padding: 0.8rem 1rem;
  background: rgba(255, 255, 255, 1);
  color: rgba(106, 64, 41, 1);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: justify;
}

.Actions_right-arrow__2CrTJ {
  float: right;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .Actions_actions__3rm8M {
    width: 25%;
  }
} ;

.Chat_main-container__1RFnG {
  display: flex;
  flex-direction: row;
  padding: 5rem;
  background-color: #ededed;
}
.Chat_left-container__rD3b3 {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 40%;
  min-width: 300px;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  background-color: #6a4029;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.Chat_text-desc__22XXV {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: white;
}

.Chat_right-container__2sz1E {
  display: block;
  width: 70%;
  min-width: 300px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  background-color: white;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.Chat_roomchat-heading__1a1GG{
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: "Poppins",sans-serif;
  font-weight: 700;
  color: #4F5665;
}

@media (max-width: 1024px) {
  .Chat_left-container__rD3b3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .Chat_text__3W6J5 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 768px) {
    .Chat_main-container__1RFnG{
      flex-direction: column;
      padding: 2rem;
      min-width: 425px;
    }
    .Chat_left-container__rD3b3 {
      width: 100%;
      max-height: 425px;
      border-radius: 1rem 1rem 0 0;
      overflow-y: scroll;
    }
    .Chat_right-container__2sz1E{
      width: 100%;
      border-radius: 0 0 1rem 1rem;
      overflow-y: scroll;
    }
  }
}

.SearchBar_form-group___rzXv{
    width: 100%;
    margin-top: 1rem;
}
.SearchBar_searchIcon__rkXjW{
    position: relative;
    -webkit-transform: translate(1.4rem, 2.6rem);
            transform: translate(1.4rem, 2.6rem);
}
.SearchBar_search-bar__8jAcX{
    width: 100%;
    height: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border: none;
    outline: none;
    border-radius: 1.5rem;
    background-color: #EFEEEE;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 1.5rem;
}
.ChatStaff_chat-container__3JA1m {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid white;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.ChatStaff_image__19o0E {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0;
  margin-right: 2rem;
}
.ChatStaff_chat-content__MJ2Ii {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ChatStaff_name__2m5l6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0;
  color: white;
}
.ChatStaff_recent-chat__28816 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0;
  color: white;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .ChatStaff_chat-container__3JA1m {
    margin: 0;
  }

}

.ChatRoom_chat-container__3Noeb {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #6a4029;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.ChatRoom_image__19NwD {
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0;
  margin-right: 1rem;
}
.ChatRoom_chat-content__12HYq {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ChatRoom_name__2n6Co {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0;
  color: #4f5665;
}
.ChatRoom_recent-chat__2ziYs {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0;
  color: #4f5665;
  text-overflow: ellipsis;
}
.ChatRoom_time__3GhFA {
  display: flex;
  justify-content: flex-end;
  width: 5rem;
  height: 3rem;
  margin-left: 1rem;
}
.ChatRoom_timestamp__1MEXa {
  color: #9f9f9f;
}

@media (max-width: 1024px) {
  .ChatRoom_chat-container__3Noeb {
    margin: 0;
  }
}

.ChatRoom_main-container__2YwZj {
    display: flex;
    flex-direction: row;
    padding: 5rem;
    background-color: #ededed;
  }
  .ChatRoom_left-container__2C8zo {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 40%;
    min-width: 300px;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    background-color: #6a4029;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .ChatRoom_text-desc__msmi- {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: white;
  }
  
  .ChatRoom_right-container__2Irvj {
    display: block;
    width: 70%;
    min-width: 300px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
    background-color: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .ChatRoom_roomchat-heading__1n5eJ{
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    color: #4F5665;
  }
  
  @media (max-width: 1024px) {
    .ChatRoom_left-container__2C8zo {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .ChatRoom_text__3CDix {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    @media (max-width: 768px) {
      .ChatRoom_main-container__2YwZj{
        flex-direction: column;
        padding: 2rem;
        min-width: 425px;
      }
      .ChatRoom_left-container__2C8zo {
        width: 100%;
        max-height: 425px;
        border-radius: 1rem 1rem 0 0;
        overflow-y: scroll;
      }
      .ChatRoom_right-container__2Irvj{
        width: 100%;
        border-radius: 0 0 1rem 1rem;
        overflow-y: scroll;
      }
    }
  }
  
.ChatItem_chat-container__3oiVr {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.ChatItem_status__hwrKd {
  display: flex;
  justify-content: flex-start;
  width: 5rem;
  height: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.ChatItem_sended__w5Sxx {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
.ChatItem_delivered__1Y5_8 {
  -webkit-filter: invert(100%);
          filter: invert(100%);
  -webkit-transform: translateX(-0.5rem);
          transform: translateX(-0.5rem);
}
.ChatItem_timestamp__9g3Jn {
  color: #9f9f9f;
}

.ChatItem_chat-content__1L8cP {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
  margin-left: 1rem;
}
.ChatItem_recent-chat__rLSAo {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0;
  color: #4f5665;
  text-overflow: ellipsis;
}
.ChatItem_image__1GeX_ {
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0;
  margin-right: 1rem;
}

@media (max-width: 1024px) {
  .ChatItem_chat-container__3oiVr {
    margin: 0;
  }
}

.NewProduct_main-container__Yw8Ph {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding: 3rem;
  background-color: white;
}
.NewProduct_left-container__2z7Ta {
  display: flex;
  flex-direction: column;
  width: 35%;
  min-width: 10rem;
}
.NewProduct_right-container__3TqHA {
  width: 65%;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (max-width: 768px) {
.NewProduct_main-container__Yw8Ph{
    flex-direction: column;
    min-width: 425px;
    padding: 1rem;
}
.NewProduct_left-container__2z7Ta{
    width: 100%;
}
.NewProduct_right-container__3TqHA{
    width: 100%;
    padding: 0;
}
  }
  
.ProductPicture_container-picture__1FdUd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 2rem;
  width: 100%;
  align-items: center;
}
.ProductPicture_img-container__1Yr8G {
  display: flex;
  justify-content: center;
  width: 13rem;
  height: 13rem;
  background: rgba(186, 186, 186, 0.35);
  border-radius: 50%;
  margin-bottom: 2rem;
}
.ProductPicture_picture__3KqaA {
  width: 4rem;
  height: 4rem;
  align-self: center;
}
.ProductPicture_btn-camera__6WXsq {
  align-self: center;
  width: 65%;
  min-width: 15rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #0b132a;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}
.ProductPicture_input-file__2_lyc{
  display: none;
}
.ProductPicture_btn-gallery__2uZik {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  min-width: 15rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #ffba33;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}

@media (max-width: 1024px) {
  .ProductPicture_img-container__1Yr8G {
    width: 10rem;
    height: 10rem;
  }
}

.DeliveryStock_container__3aX9a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 2rem;
  width: 100%;
  align-items: center;
}
.DeliveryStock_text-delivery__30stT {
  width: 65%;
  min-width: 15rem;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
}
.DeliveryStock_input-group-delivery__EKU9F {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 65%;
  min-width: 15rem;
}
.DeliveryStock_input-start__2irUi {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}

.DeliveryStock_label-start__14oOY {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-right: 1rem;
  color: #9f9f9f;
}
.DeliveryStock_time-start__1Fm3l {
  width: 150px;
  height: 3rem;
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: #6a4029;
}
.DeliveryStock_input-end__tjrNd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4rem;
  margin-top: 1rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}
.DeliveryStock_label-end__3xgc7 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-right: 1rem;
  color: #9f9f9f;
}
.DeliveryStock_time-end__12kWu {
  width: 150px;
  height: 3rem;
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: #6a4029;
}
.DeliveryStock_text-stock__2JmmM {
  width: 60%;
  min-width: 15rem;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
}
.DeliveryStock_text-stock__2JmmM::-webkit-input-placeholder {
  color: #9f9f9f;
}
.DeliveryStock_text-stock__2JmmM::placeholder {
  color: #9f9f9f;
}
.DeliveryStock_input-group-stock__D6E2B {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 65%;
  min-width: 15rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}
.DeliveryStock_label-stock__1hGVG {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 65%;
  margin: 0;
  margin-right: 1rem;
  color: #9f9f9f;
}
.DeliveryStock_input-stock__291U0 {
  align-self: center;
  height: 4rem;
  width: 4rem;
  border: none;
  outline: none;
  color: #6a4029;
}
.DeliveryStock_label-stock-pcs__13k9K {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-left: 1rem;
  color: #9f9f9f;
}
@media (max-width: 768px) {
  .DeliveryStock_btn-camera__2YWwo {
    font-size: 1rem;
    height: 3rem;
    width: 100%;
  }
  .DeliveryStock_btn-gallery__3E4VT {
    font-size: 1rem;
    height: 3rem;
    width: 100%;
  }
  .DeliveryStock_text-delivery__30stT {
    width: 100%;
  }
  .DeliveryStock_input-group-delivery__EKU9F {
    width: 100%;
  }
  .DeliveryStock_input-start__2irUi {
    height: 3rem;
  }
  .DeliveryStock_label-start__14oOY {
    font-size: 1rem;
  }
  .DeliveryStock_input-end__tjrNd {
    height: 3rem;
  }
  .DeliveryStock_label-end__3xgc7 {
    font-size: 1rem;
  }
  .DeliveryStock_text-stock__2JmmM {
    width: 100%;
  }
  .DeliveryStock_input-group-stock__D6E2B {
    width: 100%;
  }
  .DeliveryStock_input-stock__291U0 {
    width: 100%;
    height: 3rem;
  }
}

.ProductDescription_productdesc-container__2Enpz {
  margin-top: 3rem;
}
.ProductDescription_form-group__rH1nY {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ProductDescription_label__2r2ZB {
  align-self: center;
  width: 80%;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
}
.ProductDescription_input__3h0z6 {
  align-self: center;
  height: 3rem;
  width: 80%;
  margin-bottom: 2rem;
  padding: 0 1rem 0 1rem;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #4f5665;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #6a4029;
}
.ProductDescription_input__3h0z6::-webkit-input-placeholder {
  color: #9f9f9f;
}
.ProductDescription_input__3h0z6::placeholder {
  color: #9f9f9f;
}
.ProductDescription_text-area__2U1RB {
  padding: 1rem;
}
.ProductDescription_product-size__3wP4B {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ProductDescription_drink-size__2Micv {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.ProductDescription_food-size__GRcoc {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.ProductDescription_sub-label__3SR6K {
  align-self: center;
  width: 80%;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #9f9f9f;
}
.ProductDescription_option__3ZBh8 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ProductDescription_option__3ZBh8 input {
  display: none;
}
.ProductDescription_text-size__1Iz8S {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.ProductDescription_option__3ZBh8 input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.ProductDescription_text-size2__2eJOL {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.ProductDescription_option__3ZBh8 input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.ProductDescription_delivery-methods__3GMIm {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ProductDescription_text-methods__2jx1E {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 10rem;
  min-width: 4rem;
  height: 4rem;
  border: none;
  margin: 0 0.5rem 0 0.5rem;
  outline: none;
  border-radius: 1rem;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
}
.ProductDescription_btn-group__32na9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}
.ProductDescription_btn-submit__3HjSS {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background-color: #6a4029;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
}
.ProductDescription_btn-cancel__3kxi9 {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background: rgba(186, 186, 186, 0.35);
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: #4f5665;
}

@media (max-width: 1024px) {
  .ProductDescription_product-size__3wP4B {
    flex-direction: column;
  }
  .ProductDescription_delivery-methods__3GMIm {
    flex-direction: column;
  }
  .ProductDescription_text-methods__2jx1E {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ProductDescription_label__2r2ZB {
    font-size: 1rem;
    width: 100%;
  }
  .ProductDescription_sub-label__3SR6K {
    width: 100%;
  }
  .ProductDescription_input__3h0z6 {
    font-size: 1rem;
    width: 100%;
  }
  .ProductDescription_text-size__1Iz8S{
      font-size: 1.2rem;
  }
  .ProductDescription_text-size2__2eJOL{
      font-size: 1rem;
  }
  .ProductDescription_text-methods__2jx1E{
      font-size: 1rem;
      height: 3rem;
  }
  .ProductDescription_btn-submit__3HjSS{
      height: 3rem;
      font-size: 1rem;
  }
  .ProductDescription_btn-cancel__3kxi9{
    height: 3rem;
    font-size: 1rem;
}
}

.EditProduct_edit-product__2WmCG {
  margin-top: 8rem;
}

.EditProduct_content__34AhB {
  margin-top: 2rem;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem;
}

.EditProduct_image-container__3krcX {
  position: relative;
  min-width: 100%;
  width: 100%;
  margin-bottom: 1rem;
}

.EditProduct_image__3C1KI {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.EditProduct_circle__18Wv0 {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 50%;
  border: 3px solid #ffba33;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}

.EditProduct_action-image__32y77 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.8);
          transform: translate(-50%, -50%) scale(0.8);
}

.EditProduct_circle__18Wv0:hover {
  box-shadow: 0 0px 8px rgba(159, 159, 159, 0.5);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.EditProduct_delivery__3N76T {
  margin-top: 2rem;
}

.EditProduct_product__34p35 {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.EditProduct_title__1JJUt,
.EditProduct_price__2yIcf,
.EditProduct_description__1cPAl {
  font-family: Poppins;
  font-size: 3rem;
  border: none;
  outline: none;
  border-bottom: 2px solid rgba(159, 159, 159, 0.5);
  font-style: normal;
  font-weight: 900;
  text-align: left;
  transition: all ease-in-out 0.5s;
  color: rgba(0, 0, 0, 0.5);
}

.EditProduct_description__1cPAl {
  min-height: 5rem;
}

.EditProduct_title__1JJUt:focus,
.EditProduct_price__2yIcf:focus,
.EditProduct_description__1cPAl:focus {
  border-bottom: 2px solid rgba(159, 159, 159, 1);
  color: rgba(0, 0, 0, 1);
}

.EditProduct_price__2yIcf {
  font-size: 2rem;
  font-weight: 500;
}

.EditProduct_description__1cPAl {
  font-size: 1.2rem;

  font-weight: 400;
}

.EditProduct_select__3FitL {
  outline: none;
  border: none;
  padding: 1rem;
  color: rgba(159, 159, 159, 1);
  background-color: white;
  border: 1px solid rgba(159, 159, 159, 0.5);
  border-radius: 10px;
}

.EditProduct_select__3FitL option {
  font-family: Poppins;
  padding: 0.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.EditProduct_select__3FitL option:checked {
  color: rgb(43, 43, 43);
}

.EditProduct_add-container__Dy-JN {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.EditProduct_qty__1NK6I {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid rgba(159, 159, 159, 0.5);
  border-radius: 10px;
  padding: 0.75rem 1rem;
  align-items: center;
}

.EditProduct_qty__1NK6I input {
  max-width: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 1rem;
  outline: none;
  border: none;
  text-align: center;
}

.EditProduct_operator__3sm1i {
  cursor: pointer;
  color: rgba(159, 159, 159, 1);
  font-weight: 700;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.EditProduct_operator__3sm1i:hover {
  color: rgb(43, 43, 43);
}

.EditProduct_add-to-cart__3T-gF,
.EditProduct_save__2n3CL {
  padding: 1rem 2rem;
  background-color: #ffba33;
  color: rgba(106, 64, 41, 1);
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
  width: 100%;
}

.EditProduct_save__2n3CL {
  /* padding: 1.5rem 2rem; */
  /* background-color: rgba(106, 64, 41, 1); */
  color: white;
  background-color: rgba(106, 64, 41, 1);
}

.EditProduct_add-to-cart__3T-gF:hover,
.EditProduct_save__2n3CL:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.EditProduct_save__2n3CL:hover {
  color: white;
}

@media (min-width: 768px) {
  .EditProduct_content__34AhB {
    flex-direction: row;
  }

  .EditProduct_image-container__3krcX {
    min-width: 40%;
    width: 40%;
    min-height: 35rem;
    max-height: 35rem;
  }

  .EditProduct_product__34p35 {
    width: 60%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

@media (min-width: 1440px) {
  .EditProduct_edit-product__2WmCG {
    margin-top: 12rem;
  }
  .EditProduct_content__34AhB {
    grid-gap: 5rem;
    gap: 5rem;
    padding: 0 2rem;
  }
}

.MenuCheckout_item__22kRt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.MenuCheckout_menu__TzHGK {
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: black;
  align-items: center;
}

.MenuCheckout_image-container__XLh7l {
  height: 100%;
}

.MenuCheckout_image__1UrcH {
  height: 3rem;
  width: 3rem;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
}

.MenuCheckout_content__1U-V2 {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .MenuCheckout_image__1UrcH {
    height: 4.5rem;
    width: 4.5rem;
  }
}

.CheckoutList_checkout-list__255MC {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  border-bottom: 1px solid black;
  margin-bottom: 2rem;
}

.CheckoutList_row__XDOcR {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CheckoutList_subtotal__3nQ3_ {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;

  text-align: left;
}

.CheckoutList_total__1_AUq {
  margin: 1rem 0;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
}

.Checkout_checkout__2vR1P {
  padding: 2rem 1rem;
}

.Checkout_content__13TYD {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Checkout_title__3J8uP {
  margin: 1rem 0;
  color: white;
}

.Checkout_card__16VTv {
  padding: 2rem 2rem;
  background-color: white;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 1);
  min-height: 40rem;
}

.Checkout_card-title__A2aGw {
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  /* margin: 2rem 0; */
  color: black;
}

.Checkout_right-side__2DQUc {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.Checkout_right-side__2DQUc .Checkout_card__16VTv {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 2rem;
  min-height: 0;
}

.Checkout_right-title__2QM-O {
  color: white;
}

.Checkout_btn-confirm__2BVsQ {
  padding: 1rem 2rem;
  background-color: rgba(106, 64, 41, 1);
  color: white;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
  width: 100%;
}

.Checkout_btn-confirm__2BVsQ:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 1);
  color: white;
}

.Checkout_row__37Lea {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.6rem;
  gap: 0.6rem;
  margin: 1rem 0;
}

.Checkout_cc-container__213Y0,
.Checkout_bank-container__2Fp0u,
.Checkout_delivery-container__Tt3NT {
  position: relative;
  background-color: rgba(244, 123, 10, 1);
  height: 2.5rem;
  width: 3rem;
  border-radius: 5px;
}

.Checkout_bank-container__2Fp0u {
  background: linear-gradient(0deg, #895537, #895537),
    linear-gradient(0deg, #c4c4c4, #c4c4c4);
}

.Checkout_delivery-container__Tt3NT {
  background: linear-gradient(0deg, #ffba33, #ffba33),
    linear-gradient(0deg, #c4c4c4, #c4c4c4);
}

.Checkout_icon__CYHYQ {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .Checkout_checkout__2vR1P {
    padding: 4rem 0;
  }

  .Checkout_content__13TYD {
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .Checkout_card__16VTv {
    padding: 2rem 2rem;
    width: 49%;
  }

  .Checkout_right-side__2DQUc {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .Checkout_card__16VTv {
    padding: 2rem 2rem;
    width: 45%;
  }

  .Checkout_right-side__2DQUc .Checkout_card__16VTv {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem 2rem;
    min-height: 0;
  }
}

.MenuCheckout_item__27uHw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.MenuCheckout_menu__neu4o {
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: black;
  align-items: center;
}

.MenuCheckout_image-container__2Cg2l {
  height: 100%;
}

.MenuCheckout_image__379wh {
  height: 3rem;
  width: 3rem;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
}

.MenuCheckout_content__1RjeN {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .MenuCheckout_image__379wh {
    height: 4.5rem;
    width: 4.5rem;
  }
}

.CheckoutList_checkout-list__36PhA {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  border-bottom: 1px solid black;
  margin-bottom: 2rem;
}

.CheckoutList_row__2RgDM {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CheckoutList_subtotal__lJKqE {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.CheckoutList_total__2JS1h {
  margin: 1rem 0;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
}

.OrderList_order-list__2wIEE {
  position: relative;
  min-height: 55rem;
  min-width: 35%;
  display: flex;
  flex-direction: column;
}

.OrderList_card__3mfeC {
  position: absolute;
  padding: 2rem 2rem;
  background-color: white;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 1);
  min-height: 35rem;
  transition: all ease-in-out 0.5s;
  opacity: 0;
  -webkit-transform: scale(1.1) translateY(-30%);
          transform: scale(1.1) translateY(-30%);
}

.OrderList_card__3mfeC.OrderList_active__3rO4e {
  -webkit-transform: scale(1) translateY(0);
          transform: scale(1) translateY(0);
  opacity: 1;
  z-index: 2;
}

.OrderList_card__3mfeC.OrderList_next__2UpCF {
  z-index: 1;
  opacity: 1;
  -webkit-transform: scale(0.95) translateY(10%);
          transform: scale(0.95) translateY(10%);
}
.OrderList_card__3mfeC.OrderList_second__2L0lO {
  z-index: 0;
  opacity: 1;
  -webkit-transform: scale(0.85) translateY(25%);
          transform: scale(0.85) translateY(25%);
}

.OrderList_swipe-up__teU4V {
  margin-top: 1rem;
  color: white;
  padding: 1rem 2rem;
  background-color: #ffba33;
  color: rgba(106, 64, 41, 1);
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
  width: 100%;
}
.OrderList_swipe-up__teU4V img {
  margin-left: 1rem;
}

@media (min-width: 375px) {
  .OrderList_order-list__2wIEE {
    min-height: 50rem;
    min-width: 35%;
  }
}

@media (min-width: 425px) {
  .OrderList_order-list__2wIEE {
    min-height: 48rem;
    min-width: 35%;
  }
}

@media (min-width: 768px) {
  .OrderList_order-list__2wIEE {
    min-height: 45rem;
    min-width: 35%;
  }
}

@media (min-width: 1440px) {
}

.ManageOrder_checkout__3s47y {
  padding: 2rem 1rem;
}

.ManageOrder_content__1QflB {
  position: relative;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50rem;
}

.ManageOrder_title__3AP92 {
  margin: 1rem 0;
  color: white;
}

.ManageOrder_card-title__aDf2p {
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  /* margin: 2rem 0; */
  color: black;
}

.ManageOrder_right-side__1fMQa {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.ManageOrder_card__2DHlq {
  padding: 2rem 2rem;
  background-color: white;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 1);
  transition: all ease-in-out 0.5s;
  align-self: flex-end;
  position: relative;
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 2rem;
  min-height: 0;
}

.ManageOrder_right-title__39wty {
  color: white;
}

.ManageOrder_btn-confirm__2gn3l {
  padding: 1rem 2rem;
  background-color: rgba(106, 64, 41, 1);
  color: white;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
  width: 100%;
}

.ManageOrder_btn-confirm__2gn3l:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 1);
  color: white;
}

.ManageOrder_row__3XWKq {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.6rem;
  gap: 0.6rem;
  margin: 1rem 0;
}

.ManageOrder_cc-container__3ZMuZ,
.ManageOrder_bank-container__3h3Fl,
.ManageOrder_delivery-container__2z_3h {
  position: relative;
  background-color: rgba(244, 123, 10, 1);
  height: 2.5rem;
  width: 3rem;
  border-radius: 5px;
}

.ManageOrder_bank-container__3h3Fl {
  background: linear-gradient(0deg, #895537, #895537),
    linear-gradient(0deg, #c4c4c4, #c4c4c4);
}

.ManageOrder_delivery-container__2z_3h {
  background: linear-gradient(0deg, #ffba33, #ffba33),
    linear-gradient(0deg, #c4c4c4, #c4c4c4);
}

.ManageOrder_icon__e4Flm {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .ManageOrder_checkout__3s47y {
    padding: 4rem 0;
  }

  .ManageOrder_content__1QflB {
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .ManageOrder_card__2DHlq {
    padding: 2rem 2rem;
    /* width: 49%; */
  }

  .ManageOrder_right-side__1fMQa {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* justify-content: space-between; */
    width: 50%;
  }
}

@media (min-width: 992px) {
  .ManageOrder_card__2DHlq {
    padding: 2rem 2rem;
    width: 45%;
  }

  .ManageOrder_right-side__1fMQa .ManageOrder_card__2DHlq {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem 2rem;
    min-height: 0;
  }
}

.Chart_chart__1MRsw {
  position: relative;
  padding: 2rem;
}

.Chart_title__3aJAY {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.Chart_duration__3yIdQ {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: rgba(124, 130, 138, 1);
}

.Chart_more__1o9td {
  display: none;
}

@media (min-width: 768px) {
  .Chart_more__1o9td {
    position: absolute;
    display: block;
    top: 2rem;
    right: 2rem;
    max-height: 1rem;
    cursor: pointer;
  }
}

.BestStaff_best-staff__3fqlP {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.8rem 1rem;
}

.BestStaff_staff__2MlPN {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 2px solid rgba(214, 217, 220, 1);
}

.BestStaff_image__3SUWh {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  object-position: center;
}

.BestStaff_staff-info__3qml4 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}

.BestStaff_name__1iyC- {
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.BestStaff_notes__2osuU {
  font-family: Poppins;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;

  text-align: left;
}

.BestStaff_lower-section__f_Agi {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BestStaff_text__1f1ob {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

.BestStaff_progress-wrapper__1F7Or {
  /* margin: 1rem 0; */
  width: 50;
  height: 50;
}

.BestStaff_description__2UCZX {
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: rgba(124, 130, 138, 1);
}

.Dashboard_background__2g1dg {
  background-color: #ededed;
}

.Dashboard_dashboard__2bdt1 {
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
}

.Dashboard_top-section__2UKg1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.Dashboard_filter-container__1wWrx {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 1.4rem;
  gap: 1.4rem;
}

.Dashboard_radio__2QRVN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Dashboard_content__2aUaM {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.Dashboard_card__-N1_G {
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.Dashboard_left-wrapper__1g8Nn {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
}

.Dashboard_chart-wrapper__Osdtt {
  background-color: #fff;
  width: 100%;
}

.Dashboard_right-wrapper__1ruRA {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.Dashboard_btn-share__20PgH,
.Dashboard_btn-download__3BiAK {
  padding: 1rem 2rem;
  /* background-color: #ffba33; */
  background-color: rgba(106, 64, 41, 1);
  color: white;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
  width: 100%;
}

@media (min-width: 768px) {
  .Dashboard_dashboard__2bdt1 {
    padding: 4rem 1rem;
  }

  .Dashboard_content__2aUaM {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .Dashboard_left-wrapper__1g8Nn {
    width: 70%;
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.Goals_goals__2HsI- {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.8rem 1rem;
  align-items: center;
}

.Goals_title__3Zrac {
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.Goals_description__1DTht {
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: rgba(124, 130, 138, 1);
}

.NewPromo_main-container__3Y_bl {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 3rem;
    background-color: white;
  }
  .NewPromo_left-container__10Zhz {
    display: flex;
    flex-direction: column;
    width: 35%;
    min-width: 10rem;
  }
  .NewPromo_right-container__2zU_e {
    width: 65%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  @media (max-width: 768px) {
  .NewPromo_main-container__3Y_bl{
      flex-direction: column;
      min-width: 425px;
      padding: 1rem;
  }
  .NewPromo_left-container__10Zhz{
      width: 100%;
  }
  .NewPromo_right-container__2zU_e{
      width: 100%;
      padding: 0;
  }
    }
    
.LeftInput_container__1Ls2T {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 2rem;
  width: 100%;
  align-items: center;
}

.LeftInput_text-discount__2mbl5 {
  width: 65%;
  min-width: 18rem;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
}
.LeftInput_text-discount__2mbl5::-webkit-input-placeholder {
  color: #9f9f9f;
}
.LeftInput_text-discount__2mbl5::placeholder {
  color: #9f9f9f;
}
.LeftInput_input-group-discount__1ozs4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 65%;
  min-width: 18rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}
.LeftInput_label-discount__1urt9 {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 65%;
  margin: 0;
  margin-right: 1rem;
  color: #9f9f9f;
}
.LeftInput_input-discount__7zGxq {
  align-self: center;
  height: 4rem;
  width: 4rem;
  border: none;
  outline: none;
  color: #6a4029;
}
.LeftInput_label-discount-percent__2NU46 {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-left: 1rem;
  color: #9f9f9f;
}
.LeftInput_text-expire__3I3MU {
  width: 65%;
  min-width: 18rem;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
}
.LeftInput_input-group-expire__21Xsd {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  min-width: 18rem;
}
.LeftInput_input-start__PIO4o {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}

.LeftInput_label-start__2GEIs {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 21%;
  margin: 0;
  color: #9f9f9f;
}
.LeftInput_date-start__HNkhc {
  height: 3rem;
  border: none;
  outline: none;
  width: 79%;
  background-color: transparent;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #6a4029;
}
.LeftInput_input-end__1iFyp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}
.LeftInput_label-end__Csbtn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  width: 21%;
  font-weight: 400;
  margin: 0;
  margin-right: 1rem;
  color: #9f9f9f;
}
.LeftInput_date-end__2_J2n {
  height: 3rem;
  border: none;
  outline: none;
  background-color: transparent;
width: 79%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #6a4029;
}
.LeftInput_text-coupon__2G1CF {
  width: 65%;
  min-width: 18rem;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
}
.LeftInput_text-coupon__2G1CF::-webkit-input-placeholder {
  color: #9f9f9f;
}
.LeftInput_text-coupon__2G1CF::placeholder {
  color: #9f9f9f;
}
.LeftInput_input-group-coupon__MbFlW {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 65%;
  min-width: 18rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}
.LeftInput_label-coupon__n3Ag8 {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 48%;
  margin: 0;
  color: #9f9f9f;
}
.LeftInput_input-coupon__3SrNZ {
  align-self: center;
  height: 4rem;
  text-align: center;
  width: 52%;
  border: none;
  outline: none;
  color: #6a4029;
  background: transparent;
}

@media (max-width: 768px) {
  .LeftInput_btn-camera__1-bYn {
    font-size: 1rem;
    height: 3rem;
    width: 100%;
  }
  .LeftInput_btn-gallery__3K1U_ {
    font-size: 1rem;
    height: 3rem;
    width: 100%;
  }
  .LeftInput_text-expire__3I3MU {
    width: 100%;
  }
  .LeftInput_input-group-expire__21Xsd {
    width: 100%;
  }
  .LeftInput_input-start__PIO4o {
    height: 3rem;
  }
  .LeftInput_label-start__2GEIs {
    font-size: 1rem;
  }
  .LeftInput_input-end__1iFyp {
    height: 3rem;
  }
  .LeftInput_label-end__Csbtn {
    font-size: 1rem;
  }
  .LeftInput_text-discount__2mbl5 {
    width: 100%;
  }
  .LeftInput_input-group-discount__1ozs4 {
    width: 100%;
  }
  .LeftInput_input-discount__7zGxq {
    width: 100%;
    height: 3rem;
  }
  .LeftInput_text-coupon__2G1CF {
    width: 100%;
  }
  .LeftInput_input-group-coupon__MbFlW {
    width: 100%;
  }
  .LeftInput_input-coupon__3SrNZ {
    width: 100%;
    height: 3rem;
  }
}

.RightInput_promo-container__3zV7i {
  margin-top: 3rem;
}
.RightInput_form-group__2gLtN {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.RightInput_label__2e1Uq {
  align-self: center;
  width: 80%;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
}
.RightInput_input__zGsn7 {
  align-self: center;
  height: 3rem;
  width: 80%;
  margin-bottom: 2rem;
  padding: 0 1rem 0 1rem;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #4f5665;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #6a4029;
}
.RightInput_input__zGsn7::-webkit-input-placeholder {
  color: #9f9f9f;
}
.RightInput_input__zGsn7::placeholder {
  color: #9f9f9f;
}
.RightInput_text-area__vIlgd {
  padding: 1rem;
}
.RightInput_product-size__qDrNb {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.RightInput_drink-size__2pWU6 {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.RightInput_food-size__g8z3f {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.RightInput_sub-label__2DWjS {
  align-self: center;
  width: 80%;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #9f9f9f;
}
.RightInput_option__3LhxY {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.RightInput_option__3LhxY input {
  display: none;
}
.RightInput_text-size__2Wvsu {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.RightInput_option__3LhxY input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.RightInput_text-size2__2vNOD {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.RightInput_option__3LhxY input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.RightInput_delivery-methods__26SZZ {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.RightInput_text-methods__2CAiC {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 10rem;
  min-width: 4rem;
  height: 4rem;
  border: none;
  margin: 0 0.5rem 0 0.5rem;
  outline: none;
  border-radius: 1rem;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
}
.RightInput_btn-group__1Xfi8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}
.RightInput_btn-submit__20S7M {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background-color: #6a4029;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
}
.RightInput_btn-cancel__2yQUw {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background: rgba(186, 186, 186, 0.35);
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: #4f5665;
}

@media (max-width: 1024px) {
  .RightInput_product-size__qDrNb {
    flex-direction: column;
  }
  .RightInput_delivery-methods__26SZZ {
    flex-direction: column;
  }
  .RightInput_text-methods__2CAiC {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .RightInput_label__2e1Uq {
    font-size: 1rem;
    width: 100%;
  }
  .RightInput_sub-label__2DWjS {
    width: 100%;
  }
  .RightInput_input__zGsn7 {
    font-size: 1rem;
    width: 100%;
  }
  .RightInput_text-size__2Wvsu {
    font-size: 1.2rem;
  }
  .RightInput_text-size2__2vNOD {
    font-size: 1rem;
  }
  .RightInput_text-methods__2CAiC {
    font-size: 1rem;
    height: 3rem;
  }
  .RightInput_btn-submit__20S7M {
    height: 3rem;
    font-size: 1rem;
  }
  .RightInput_btn-cancel__2yQUw {
    height: 3rem;
    font-size: 1rem;
  }
}

.EditPromo_main-container__36hEh {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 3rem;
    background-color: white;
  }
  .EditPromo_left-container__36kNo {
    display: flex;
    flex-direction: column;
    width: 35%;
    min-width: 10rem;
  }
  .EditPromo_right-container__3NlpA {
    width: 65%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  @media (max-width: 768px) {
  .EditPromo_main-container__36hEh{
      flex-direction: column;
      min-width: 425px;
      padding: 1rem;
  }
  .EditPromo_left-container__36kNo{
      width: 100%;
  }
  .EditPromo_right-container__3NlpA{
      width: 100%;
      padding: 0;
  }
    }
    
.LeftInput_container__1-pgA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 2rem;
  width: 100%;
  align-items: center;
}

.LeftInput_text-expire__2hoKQ {
  width: 65%;
  min-width: 18rem;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
}
.LeftInput_input-group-expire__3JQ3T {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  min-width: 18rem;
}
.LeftInput_input-start__C68md {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}

.LeftInput_label-start__2VXvO {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 21%;
  margin: 0;
  color: #9f9f9f;
}
.LeftInput_date-start__PTDUm {
  height: 3rem;
  border: none;
  outline: none;
  width: 79%;
  background-color: transparent;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #6a4029;
}
.LeftInput_input-end__3R8sE {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}
.LeftInput_label-end__3jaF2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  width: 21%;
  font-weight: 400;
  margin: 0;
  margin-right: 1rem;
  color: #9f9f9f;
}
.LeftInput_date-end__2z2QB {
  height: 3rem;
  border: none;
  outline: none;
  background-color: transparent;
width: 79%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #6a4029;
}
.LeftInput_text-coupon__33mlK {
  width: 65%;
  min-width: 18rem;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
}
.LeftInput_text-coupon__33mlK::-webkit-input-placeholder {
  color: #9f9f9f;
}
.LeftInput_text-coupon__33mlK::placeholder {
  color: #9f9f9f;
}
.LeftInput_input-group-coupon__1t1X4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 65%;
  min-width: 18rem;
  padding: 0 1rem 0 1rem;
  border: 2px solid #9f9f9f;
  border-radius: 1rem;
}
.LeftInput_label-coupon__3irVh {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 48%;
  margin: 0;
  color: #9f9f9f;
}
.LeftInput_input-coupon__1VzYt {
  align-self: center;
  height: 4rem;
  text-align: center;
  width: 52%;
  border: none;
  outline: none;
  color: #6a4029;
  background: transparent;
}

@media (max-width: 768px) {
  .LeftInput_btn-camera__CEzJD {
    font-size: 1rem;
    height: 3rem;
    width: 100%;
  }
  .LeftInput_btn-gallery__3-Lkn {
    font-size: 1rem;
    height: 3rem;
    width: 100%;
  }
  .LeftInput_text-expire__2hoKQ {
    width: 100%;
  }
  .LeftInput_input-group-expire__3JQ3T {
    width: 100%;
  }
  .LeftInput_input-start__C68md {
    height: 3rem;
  }
  .LeftInput_label-start__2VXvO {
    font-size: 1rem;
  }
  .LeftInput_input-end__3R8sE {
    height: 3rem;
  }
  .LeftInput_label-end__3jaF2 {
    font-size: 1rem;
  }
  .LeftInput_text-discount__26Te5 {
    width: 100%;
  }
  .LeftInput_input-group-discount__QhEwm {
    width: 100%;
  }
  .LeftInput_input-discount__2va9m {
    width: 100%;
    height: 3rem;
  }
  .LeftInput_text-coupon__33mlK {
    width: 100%;
  }
  .LeftInput_input-group-coupon__1t1X4 {
    width: 100%;
  }
  .LeftInput_input-coupon__1VzYt {
    width: 100%;
    height: 3rem;
  }
}

.RightInput_promo-container__jVLgA {
  margin-top: 3rem;
}
.RightInput_form-group__2EFx9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.RightInput_label__29CKv {
  align-self: center;
  width: 80%;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
}
.RightInput_input__3B7_h {
  align-self: center;
  height: 3rem;
  width: 80%;
  margin-bottom: 2rem;
  padding: 0 1rem 0 1rem;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #4f5665;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #6a4029;
}
.RightInput_input__3B7_h::-webkit-input-placeholder {
  color: #9f9f9f;
}
.RightInput_input__3B7_h::placeholder {
  color: #9f9f9f;
}
.RightInput_text-area__2sBQ1 {
  padding: 1rem;
}
.RightInput_product-size__3Psf1 {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.RightInput_drink-size__1u_0z {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.RightInput_food-size__17HBQ {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.RightInput_sub-label__3sbl3 {
  align-self: center;
  width: 80%;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #9f9f9f;
}
.RightInput_option__187No {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.RightInput_option__187No input {
  display: none;
}
.RightInput_text-size__3EZve {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.RightInput_option__187No input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.RightInput_text-size2__3_7_M {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.RightInput_option__187No input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.RightInput_delivery-methods__1TWqN {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.RightInput_text-methods__1qaoJ {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 10rem;
  min-width: 4rem;
  height: 4rem;
  border: none;
  margin: 0 0.5rem 0 0.5rem;
  outline: none;
  border-radius: 1rem;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
}
.RightInput_text-discount__3yDuX {
  align-self: center;
  min-width: 18rem;
  margin-top: 2rem;
  width: 80%;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
}
.RightInput_text-discount__3yDuX::-webkit-input-placeholder {
  color: #9f9f9f;
}
.RightInput_text-discount__3yDuX::placeholder {
  color: #9f9f9f;
}
.RightInput_input-group-discount__koHEt {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 80%;
  min-width: 18rem;
  padding: 0 1rem 0 1rem;
  border-bottom: 2px solid #9f9f9f;
}
.RightInput_label-discount__q777v {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 65%;
  margin: 0;
  margin-right: 1rem;
  color: #9f9f9f;
}
.RightInput_input-discount__2PR2V {
  align-self: center;
  height: 4rem;
  width: 4rem;
  border: none;
  outline: none;
  color: #6a4029;
}
.RightInput_label-discount-percent__2mwbl {
  align-self: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-left: 1rem;
  color: #9f9f9f;
}

.RightInput_btn-group__1vLAu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}
.RightInput_btn-submit__2E1x4 {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background-color: #6a4029;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
}
.RightInput_btn-cancel__1-Ejw {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background: rgba(186, 186, 186, 0.35);
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: #4f5665;
}

@media (max-width: 1024px) {
  .RightInput_product-size__3Psf1 {
    flex-direction: column;
  }
  .RightInput_delivery-methods__1TWqN {
    flex-direction: column;
  }
  .RightInput_text-methods__1qaoJ {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .RightInput_label__29CKv {
    font-size: 1rem;
    width: 100%;
  }
  .RightInput_sub-label__3sbl3 {
    width: 100%;
  }
  .RightInput_input__3B7_h {
    font-size: 1rem;
    width: 100%;
  }
  .RightInput_text-size__3EZve {
    font-size: 1.2rem;
  }
  .RightInput_text-size2__3_7_M {
    font-size: 1rem;
  }
  .RightInput_text-methods__1qaoJ {
    font-size: 1rem;
    height: 3rem;
  }
  .RightInput_btn-submit__2E1x4 {
    height: 3rem;
    font-size: 1rem;
  }
  .RightInput_btn-cancel__1-Ejw {
    height: 3rem;
    font-size: 1rem;
  }
  .RightInput_text-discount__3yDuX {
    width: 100%;
    height: 3rem;
    font-size: 1rem;
  }
  .RightInput_input-group-discount__koHEt {
    width: 100%;
  }
}

.PromoCard_card-container__h2QEQ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  background-color: #ffcb65;
  border-radius: 0.5rem;
  width: 65%;
  min-width: 18rem;

}

.PromoCard_card-top__1olId {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: none;
  border-bottom: 1px dashed black;
  padding: 2rem;
}
.PromoCard_img-edit__QY6b1 {
  display: flex;
  flex-direction: row;
  -webkit-transform: translateX(1.5rem);
          transform: translateX(1.5rem);
}
.PromoCard_promo-image__3SIL_{
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
}
.PromoCard_btn-edit__sOmiX{
    align-self: flex-end;
    height: -webkit-min-content;
    height: min-content;
    background: none;
    border: none;
    outline: none;
    -webkit-transform: translateX(-2rem);
            transform: translateX(-2rem);
}
.PromoCard_text-name__3VIRv{
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    text-align: center;
}
.PromoCard_discount__1ZH__{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    text-align: center;
}
.PromoCard_text-desc__3Hp-Z{
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
}
.PromoCard_card-bottom__2Pdcr {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: none;
    padding: 2rem;
  }
.PromoCard_text-coupon__ZAtVu{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
}
.PromoCard_coupon-code__JqZda{
    margin-top: 1rem;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}
.PromoCard_coupon-valid__1SAQx{
    margin: 0;
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;

}
