@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gray-2 {
  background-color: #bcbaba;
}

.section-title {
  font-family: Rubik;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  margin-bottom: 1rem;
}

.section-description {
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: #4f5665;
}

@media (min-width: 1440px) {
  .container {
    max-width: 90%;
  }
}
