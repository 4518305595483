.container-main {
  display: block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 1rem;
  padding-right: 1rem;
}
.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title {
  font-family: "Poppins";
  font-weight: 700;
  color: white;
  margin-top: 7rem;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}
.text-desc {
  font-family: "Poppins";
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}
.form-group-email {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.email-input {
  width: 50%;
  height: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 3rem;
  border: 1px solid #4f5665;
  border-radius: 1rem;
  outline: none;
  font-family: "Poppins";
  font-size: 1.4rem;
  font-weight: 400;
  color: #6a4029;
  vertical-align: center;
}
.email-input::placeholder {
  color: #9f9f9f;
}
.btn-send {
  width: 10rem;
  height: 5rem;
  background-color: #ffba33;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  border: none;
  outline: none;
  box-shadow: 0px 6px 20px rgba(255, 186, 51, 0.4);
}
.btn-resend {
  align-self: center;
  width: 20%;
  min-width: 10rem;
  height: 5rem;
  margin-top: 3rem;
  background-color: #6a4029;
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  border-radius: 1rem;
  border: none;
  outline: none;
  box-shadow: 0px 6px 20px rgba(106, 64, 41, 0.46);
}
.countdown {
  align-self: center;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 5rem;
  font-family: "Poppins";
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}

@media (max-width: 1024px) {
  .form-group-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .email-input {
    align-self: center;
    width: 70%;
    height: 5rem;
    margin: 0;
    margin-bottom: 2rem;
  }
  .btn-send {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
  }
  .btn-resend {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
    margin-top: 1rem;
  }
}

@media (max-width: 485px) {
  .container-main {
    height: 85vh;
    min-width: 395px;
  }
  .title {
    font-size: 2rem;
  }
  .text-desc {
    font-size: 1rem;
  }
  .form-group-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .email-input {
    align-self: center;
    width: 95%;
    height: 4rem;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.15rem;
  }
  .btn-send {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
  }
  .btn-resend {
    align-self: center;
    width: 30%;
    height: 4rem;
    min-width: 10rem;
    margin-top: 1rem;
  }
}
