.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  background-color: #ffcb65;
  border-radius: 0.5rem;
  width: 65%;
  min-width: 18rem;

}

.card-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: none;
  border-bottom: 1px dashed black;
  padding: 2rem;
}
.img-edit {
  display: flex;
  flex-direction: row;
  transform: translateX(1.5rem);
}
.promo-image{
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
}
.btn-edit{
    align-self: flex-end;
    height: min-content;
    background: none;
    border: none;
    outline: none;
    transform: translateX(-2rem);
}
.text-name{
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    text-align: center;
}
.discount{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    text-align: center;
}
.text-desc{
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
}
.card-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: none;
    padding: 2rem;
  }
.text-coupon{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
}
.coupon-code{
    margin-top: 1rem;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}
.coupon-valid{
    margin: 0;
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;

}