.chat-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid white;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.image {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0;
  margin-right: 2rem;
}
.chat-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.name {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0;
  color: white;
}
.recent-chat {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0;
  color: white;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .chat-container {
    margin: 0;
  }

}
