.background {
  background-color: #ededed;
}

.dashboard {
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
}

.top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
}

.radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.card {
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.left-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.chart-wrapper {
  background-color: #fff;
  width: 100%;
}

.right-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.btn-share,
.btn-download {
  padding: 1rem 2rem;
  /* background-color: #ffba33; */
  background-color: rgba(106, 64, 41, 1);
  color: white;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
  width: 100%;
}

@media (min-width: 768px) {
  .dashboard {
    padding: 4rem 1rem;
  }

  .content {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  .left-wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: fit-content;
  }
}
