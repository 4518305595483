.goals {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.8rem 1rem;
  align-items: center;
}

.title {
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.description {
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: rgba(124, 130, 138, 1);
}
