.container-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 2rem;
  width: 100%;
  align-items: center;
}
.img-container {
  display: flex;
  justify-content: center;
  width: 13rem;
  height: 13rem;
  background: rgba(186, 186, 186, 0.35);
  border-radius: 50%;
  margin-bottom: 2rem;
}
.picture {
  width: 4rem;
  height: 4rem;
  align-self: center;
}
.btn-camera {
  align-self: center;
  width: 65%;
  min-width: 15rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #0b132a;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}
.input-file{
  display: none;
}
.btn-gallery {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  min-width: 15rem;
  height: 4rem;
  margin-bottom: 1rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #ffba33;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(11, 19, 42, 0.24);
}

@media (max-width: 1024px) {
  .img-container {
    width: 10rem;
    height: 10rem;
  }
}
