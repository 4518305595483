.main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
  background-color: #ededed;
}
.left-container {
  width: 40%;
  padding-left: 2rem;
  padding-right: 2rem;
}
.right-container {
  width: 60%;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
  .left-container {
    width: 100%;
  }
  .right-container {
    width: 100%;
  }
}
@media (max-width: 675px) {
  .main-container {
    min-width: 425px;
    padding: 1rem;
  }
  .left-container {
    padding: 0;
  }
  .right-container {
    padding: 0;
  }
}
