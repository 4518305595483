.featured {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.img-illustration {
  object-fit: cover;
  width: 100%;
  object-position: center;
}

.perks {
  font-family: Rubik;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #4f5665;
}

.checklist {
  margin-right: 1rem;
}

@media (min-width: 768px) {
  /* .featured {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  } */
}

@media (min-width: 992px) {
  .illustration,
  .perks-container {
    padding: 2rem;
  }

  .featured {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
