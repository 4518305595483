.methods-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
}
.text-methods{
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    margin-bottom: 2rem;
}
.btn-group{
    align-self: center;
    text-align: center;
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 2rem;
}
.btn-dinein{
    width: 10rem;
    height: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #F4F4F8;
    border: none;
    outline: none;
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #9F9F9F;
}
.btn-dinein:hover{
    background-color: #FFBA33;
    color: black;
}
.btn-delivery{
    width: 10rem;
    height: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #F4F4F8;
    border: none;
    outline: none;
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #9F9F9F;
}
.btn-delivery:hover{
    background-color: #FFBA33;
    color: black;
}
.btn-pickup{
    width: 10rem;
    height: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #F4F4F8;
    border: none;
    outline: none;
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #9F9F9F;
}
.btn-pickup:hover{
    background-color: #FFBA33;
    color: black;
}
.label-input{
    font-family: "Poppins",sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    margin-right: 1rem;
}
.time{
    width: 150px;
    height: 3rem;
    border: none;
    outline: none;
    border-bottom: 2px solid #9F9F9F;
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    color: #6A4029;
}
small{
    margin-top: 1rem;
    margin-bottom: 5rem;
    font-family: "Poppins",sans-serif;
    font-style: italic;
    font-size: 1rem;
    font-weight: 400;
    color: #9F9F9F;
}