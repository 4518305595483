.desc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  padding: 4rem;
  background-color: white;
  border-radius: 2rem;
}
.delivery-text {
  display: inline-flex;
}
.text-default {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #6a4029;
}
.text-props {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
}
.desc-product {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #6a4029;
}
.text-size {
  text-align: center;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: black;
}
.btn-group {
  align-self: center;
  text-align: center;
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}
.btn-r {
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #f4f4f8;
  border: none;
  outline: none;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #9f9f9f;
}
.btn-r:hover {
  background-color: #ffba33;
  color: black;
}
.btn-l {
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #f4f4f8;
  border: none;
  outline: none;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #9f9f9f;
}
.btn-l:hover {
  background-color: #ffba33;
  color: black;
}
.btn-xl {
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #f4f4f8;
  border: none;
  outline: none;
  border-radius: 500px;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #9f9f9f;
}
.btn-xl:hover {
  background-color: #ffba33;
  color: black;
}
.btn-xl:checked {
  background-color: #ffba33;
  color: black;
}
@media (max-width: 1024px) {
  .desc-container {
    padding: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .text-size {
    text-align: center;
    margin-top: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: black;
  }

  .btn-r {
    width: 3rem;
    height: 3rem;
    margin: 0;
    font-size: 1.4rem;
  }

  .btn-l {
    width: 3rem;
    height: 3rem;
    margin: 0;
    font-size: 1.4rem;
  }
  .btn-xl {
    width: 3rem;
    height: 3rem;
    margin: 0;
    font-size: 1.4rem;
  }
}
