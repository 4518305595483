.actions {
  display: flex;
  flex-direction: column;
}

.title {
  color: white;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.btn-save {
  background: rgba(106, 64, 41, 1);
  color: white;
  padding: 0.8rem;
  border-radius: 15px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1rem;
}

.btn-save:hover {
  color: whitesmoke;
}

.btn-cancel {
  border-radius: 15px;
  padding: 0.8rem 0;
  background: rgba(255, 186, 51, 1);
  color: rgba(106, 64, 41, 1);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2rem;
}

.btn-edit-password,
.btn-logout {
  border-radius: 15px;
  padding: 0.8rem 1rem;
  background: rgba(255, 255, 255, 1);
  color: rgba(106, 64, 41, 1);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: justify;
}

.right-arrow {
  float: right;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .actions {
    width: 25%;
  }
} ;
