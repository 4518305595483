.productinfo-container{
display: flex;
flex-direction: column;
justify-content: center;
margin-top: 4rem;
margin-bottom: 2rem;
}
.img-product{
    align-self: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin-bottom: 2rem;
    filter: drop-shadow(0px 40px 40px rgba(0, 0, 0, 0.07));
}
.title-product{
    align-self: center;
    text-align: center;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
    font-weight: 900;
}
.price-product{
    align-self: center;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
}
@media (max-width: 1024px) {
    .img-product{
        width: 200px;
        height: 200px;
    }
    .title-product{
        font-size: 2.5rem;
    }
    .price-product{
        font-size: 1.5rem;
    }
  }