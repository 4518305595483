.edit-product {
  margin-top: 8rem;
}

.content {
  margin-top: 2rem;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 2rem;
  padding: 1rem;
}

.image-container {
  position: relative;
  min-width: 100%;
  width: 100%;
  margin-bottom: 1rem;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.circle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 50%;
  border: 3px solid #ffba33;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}

.action-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
}

.circle:hover {
  box-shadow: 0 0px 8px rgba(159, 159, 159, 0.5);
  transform: scale(1.1);
}

.delivery {
  margin-top: 2rem;
}

.product {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.title,
.price,
.description {
  font-family: Poppins;
  font-size: 3rem;
  border: none;
  outline: none;
  border-bottom: 2px solid rgba(159, 159, 159, 0.5);
  font-style: normal;
  font-weight: 900;
  text-align: left;
  transition: all ease-in-out 0.5s;
  color: rgba(0, 0, 0, 0.5);
}

.description {
  min-height: 5rem;
}

.title:focus,
.price:focus,
.description:focus {
  border-bottom: 2px solid rgba(159, 159, 159, 1);
  color: rgba(0, 0, 0, 1);
}

.price {
  font-size: 2rem;
  font-weight: 500;
}

.description {
  font-size: 1.2rem;

  font-weight: 400;
}

.select {
  outline: none;
  border: none;
  padding: 1rem;
  color: rgba(159, 159, 159, 1);
  background-color: white;
  border: 1px solid rgba(159, 159, 159, 0.5);
  border-radius: 10px;
}

.select option {
  font-family: Poppins;
  padding: 0.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.select option:checked {
  color: rgb(43, 43, 43);
}

.add-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.qty {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid rgba(159, 159, 159, 0.5);
  border-radius: 10px;
  padding: 0.75rem 1rem;
  align-items: center;
}

.qty input {
  max-width: 2rem;
  width: fit-content;
  margin: 0 1rem;
  outline: none;
  border: none;
  text-align: center;
}

.operator {
  cursor: pointer;
  color: rgba(159, 159, 159, 1);
  font-weight: 700;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.operator:hover {
  color: rgb(43, 43, 43);
}

.add-to-cart,
.save {
  padding: 1rem 2rem;
  background-color: #ffba33;
  color: rgba(106, 64, 41, 1);
  border-radius: 10px;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: 15rem;
  align-self: center;
  width: 100%;
}

.save {
  /* padding: 1.5rem 2rem; */
  /* background-color: rgba(106, 64, 41, 1); */
  color: white;
  background-color: rgba(106, 64, 41, 1);
}

.add-to-cart:hover,
.save:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.save:hover {
  color: white;
}

@media (min-width: 768px) {
  .content {
    flex-direction: row;
  }

  .image-container {
    min-width: 40%;
    width: 40%;
    min-height: 35rem;
    max-height: 35rem;
  }

  .product {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@media (min-width: 1440px) {
  .edit-product {
    margin-top: 12rem;
  }
  .content {
    gap: 5rem;
    padding: 0 2rem;
  }
}
