.main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 3rem;
    background-color: white;
  }
  .left-container {
    display: flex;
    flex-direction: column;
    width: 35%;
    min-width: 10rem;
  }
  .right-container {
    width: 65%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  @media (max-width: 768px) {
  .main-container{
      flex-direction: column;
      min-width: 425px;
      padding: 1rem;
  }
  .left-container{
      width: 100%;
  }
  .right-container{
      width: 100%;
      padding: 0;
  }
    }
    