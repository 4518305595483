.history {
  padding: 4rem 0;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
  gap: 1.8rem;
}

@media (min-width: 768px) {
  .history {
    justify-content: flex-start;
  }
}
