.edit {
  /* position: absolute;
  top: 1rem;
  right: 1rem; */
  position: relative;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  background-color: rgba(106, 64, 41, 1);
  cursor: pointer;
  animation: normal ease-in-out 0.5s;
}

.edit:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.1);
}

.pencil {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
