.stats {
  padding: 1rem;
  width: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding: 1rem;
  transform: translatey(-50%);
  background-color: #fff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
}

.circle {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #ffba33;
  margin-right: 1rem;
}

.circle-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.stat-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
}

.text {
  font-size: 0.8rem;
  color: #4f5665;

  font-style: normal;
  font-weight: 400;

  text-align: left;
}

@media (max-width: 374px) {
  .stats {
    flex-direction: column;
    align-items: center;
    width: 70%;
  }
}

@media (min-width: 375px) {
  .stats {
    width: 90%;
    padding: 1rem;
  }
}

@media (min-width: 768px) {
  .stats {
    padding: 2rem 3rem;
  }

  .number {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
  }

  .text {
    font-size: 1.3rem;
    color: #4f5665;

    font-style: normal;
    font-weight: 400;

    text-align: left;
  }
}

@media (min-width: 992px) {
  .stats {
    padding: 3rem 5rem;
  }
}

@media (min-width: 1200px) {
  .stats {
    padding: 3rem 6rem;
  }
  .circle {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: #ffba33;
    margin-right: 1rem;
  }

  .circle-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 1440px) {
  .stats {
    padding: 3rem 10rem;
  }
}
