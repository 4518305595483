.productdesc-container {
  margin-top: 3rem;
}
.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.label {
  align-self: center;
  width: 80%;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
}
.input {
  align-self: center;
  height: 3rem;
  width: 80%;
  margin-bottom: 2rem;
  padding: 0 1rem 0 1rem;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #4f5665;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #6a4029;
}
.input::placeholder {
  color: #9f9f9f;
}
.text-area {
  padding: 1rem;
}
.product-size {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.drink-size {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.food-size {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.sub-label {
  align-self: center;
  width: 80%;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #9f9f9f;
}
.option {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.option input {
  display: none;
}
.text-size {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.option input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.text-size2 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
  text-align: center;
}
.option input[type="checkbox"]:checked ~ span {
  background: #ffba33;
  color: black;
}
.delivery-methods {
  align-self: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.text-methods {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  width: 10rem;
  min-width: 4rem;
  height: 4rem;
  border: none;
  margin: 0 0.5rem 0 0.5rem;
  outline: none;
  border-radius: 1rem;
  background: rgba(186, 186, 186, 0.35);
  color: #9f9f9f;
}
.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}
.btn-submit {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background-color: #6a4029;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
}
.btn-cancel {
  align-self: center;
  height: 4rem;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background: rgba(186, 186, 186, 0.35);
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: #4f5665;
}

@media (max-width: 1024px) {
  .product-size {
    flex-direction: column;
  }
  .delivery-methods {
    flex-direction: column;
  }
  .text-methods {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .label {
    font-size: 1rem;
    width: 100%;
  }
  .sub-label {
    width: 100%;
  }
  .input {
    font-size: 1rem;
    width: 100%;
  }
  .text-size{
      font-size: 1.2rem;
  }
  .text-size2{
      font-size: 1rem;
  }
  .text-methods{
      font-size: 1rem;
      height: 3rem;
  }
  .btn-submit{
      height: 3rem;
      font-size: 1rem;
  }
  .btn-cancel{
    height: 3rem;
    font-size: 1rem;
}
}
