.checkout-list {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 1px solid black;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subtotal {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.total {
  margin: 1rem 0;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
}
