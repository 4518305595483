.brand-logo {
  height: 2rem;
  margin-right: 1rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.btn-signup {
  background: #ffba33;
  height: 2.4rem;
  padding: 0 1rem;
  border-radius: 3rem;
}

@media (min-width: 768px) {
  /* nav {
    margin: 0 4rem;
  } */

  .button-container {
    display: block;
  }
}

@media (min-width: 992px) {
  nav {
    /* margin: 0 1rem; */
    height: 5rem;
  }
}
