.menu-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 45%;
  width: 45%;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 1rem 0.5rem;
  position: relative;
  cursor: pointer;
  transition: all ease 0.5s;
}

.menu-item:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.image {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 40%;
  margin: 1rem 0;
  position: absolute;
  transform: translateY(-100%);
  box-shadow: 0 12px 10px rgba(0, 0, 0, 0.4);
}

.menu-item:hover > .image {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
}

.menu-name {
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  margin-top: 2.5rem;
}

.price {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.edit {
  position: absolute;
  bottom: 0rem;
  right: 0rem;

  transform: scale(0.9) translateX(20%) translateY(20%);
}

@media (max-width: 424px) {
  .menu-item {
    min-width: 100%;
    width: 100%;
    padding: 1rem 0.5rem;
  }
}

@media (min-width: 768px) {
  .menu-item {
    min-width: 30%;
    width: 30%;
    padding: 1rem 0.5rem;
  }
}

@media (min-width: 1200px) {
  .menu-item {
    min-width: 22%;
    width: 22%;
    padding: 1rem 0.5rem;
  }
}
