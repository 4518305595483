.partner-container {
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
}

.image {
  object-fit: contain;
  width: 100%;
  height: 3rem;
}

@media (min-width: 768px) {
  .partner-container {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .image {
    height: 4rem;
  }
}
