.main-container {
    display: flex;
    flex-direction: row;
    padding: 5rem;
    background-color: #ededed;
  }
  .left-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 40%;
    min-width: 300px;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    background-color: #6a4029;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .text-desc {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: white;
  }
  
  .right-container {
    display: block;
    width: 70%;
    min-width: 300px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
    background-color: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .roomchat-heading{
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    color: #4F5665;
  }
  
  @media (max-width: 1024px) {
    .left-container {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .text {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    @media (max-width: 768px) {
      .main-container{
        flex-direction: column;
        padding: 2rem;
        min-width: 425px;
      }
      .left-container {
        width: 100%;
        max-height: 425px;
        border-radius: 1rem 1rem 0 0;
        overflow-y: scroll;
      }
      .right-container{
        width: 100%;
        border-radius: 0 0 1rem 1rem;
        overflow-y: scroll;
      }
    }
  }
  