.main {
  margin-top: 54px;
}

@media (min-wdith: 768px) {
  .main {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .main {
    margin-top: 5rem;
  }
}
