main.container-main {
  display: block;
  width: 100%;
  height: 120%;
  text-decoration: none;
}
.row {
  margin-bottom: 4rem;
}

.img-container {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  margin-bottom: -250px;
  /* background-image: url(./img_auth.png); */
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  padding: 3rem;
  margin: 0;
  margin-bottom: -8rem;
}
.upper-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-arkaffe {
  align-self: center;
  font-size: 1.2rem;
  font-weight: 700;
}
.text-arkaffe:hover {
  text-decoration: none;
  color: inherit;
}
.text-arkaffe img {
  margin-right: 1rem;
}
.btn.btn-signup {
  width: 8rem;
  height: auto;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: #ffba33;
  border-radius: 2rem;
  box-shadow: 0px 6px 20px rgba(255, 186, 51, 0.4);
}

.middle-section {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
}
.middle-section .title {
  margin-top: 5rem;
  margin-bottom: 2rem;
  font-size: 2.2rem;
  font-weight: 800;
  color: #6a4029;
}

.form-group {
  margin-top: 3rem;
}
.form-group input {
  height: 4rem;
  width: 100%;
  padding-left: 1.5rem;
  border: 1px solid #4f5665;
  border-radius: 1rem;
  outline: none;
  font-size: 1.4rem;
  font-weight: 400;
  color: #6a4029;
  vertical-align: center;
}
.form-group label {
  position: absolute;
}
.form-group label span {
  position: relative;
  top: -2rem;

  font-weight: 700;
  font-size: 1.2rem;
  color: #4f5665;
}
.form-group span {
  position: absolute;
}
.form-group span img {
  position: relative;
  top: 1rem;
  right: 3rem;
  cursor: pointer;
}
.form-group input::placeholder {
  color: #9f9f9f;
}
.react-tel-input{
  margin-top: 1rem;
}
.react-tel-input .special-label{
  position: relative;
  font-weight: 700;
  font-size: 1.2rem;
  color: #4f5665;
text-align: start;
}
.react-tel-input .form-control {
  width: 100% ;
  height: 4rem;
  padding-left: 1.5rem;
  border: 1px solid #4f5665;
  border-radius: 1rem;
  outline: none;
  font-size: 1.4rem;
  font-weight: 400;
  color: #6a4029;
}

.link-reset {
  position: relative;
  text-align: start;
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: #4f5665;
}
.link-reset:hover {
  color: #6a4029;
}

.button-group {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.btn-login {
  width: 100%;
  height: 4rem;
  background-color: #ffba33;
  font-size: 1.4rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}
.btn-google {
  width: 100%;
  height: 4rem;
  margin-top: 2rem;
  background-color: white;

  font-size: 1.2rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}
.btn-google img {
  margin-right: 1rem;
}
.bottom-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  width: 100%;
  bottom: 0%;
  padding: 4rem;
}

.popup {
  display: block;
  height: fit-content;
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: 11rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}
.popup-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
}
.button-popup {
  text-align: end;
}
.btn-member {
  width: 15rem;
  height: 3.5rem;
  background-color: #ffba33;

  font-size: 1rem;
  font-weight: 700;
  color: #6a4029;
  border-radius: 1rem;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.67);
}

@media (max-width: 1024px) {
  .middle-section {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .popup-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .popup {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (max-width: 768px) {
  .img-container {
    display: none;
  }
  .login-container {
    width: 100% !important;
  }
  .popup {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .popup-content {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 425px) {
  .container-main {
    min-width: 400px;
  }
  .login-container {
    padding: 1.5rem;
  }
  .popup-content h3 {
    font-size: 1.3rem;
  }
}
