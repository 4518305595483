.testimonial-list {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow-x: scroll;
}

.testimonial ::-webkit-scrollbar {
  display: none;
}

.wrapper {
  min-width: 100%;
}

.indicator-container {
  display: inline-flex;
  gap: 1rem;
}

.indicator {
  display: block;
  min-width: 10px;
  width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: #dde0e4;
  cursor: pointer;
}

.indicator.active {
  width: 2rem;
  border-radius: 10px;
  background-color: #6a4029;
}

.arrow-container {
  display: inline-flex;
  gap: 1rem;
}

.circle {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 2px solid #6a4029;
  transition: all ease-in-out 0.3s;
}

.circle.active,
.circle:hover {
  background-color: #6a4029;
}

.circle:hover {
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #6a4029;
}

.circle:hover > .arrow {
  fill: #fff;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .wrapper {
    min-width: 50%;
  }
}

@media (min-width: 992px) {
  .wrapper {
    min-width: 33.3%;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    min-width: 27.5%;
  }
}
