.item {
  border: 3px solid #dddddd;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  transition: all ease-in-out 0.5s;
  min-height: 11rem;
}

.item:hover {
  border: 3px solid #6a4029;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.name,
.address {
  margin: 0;
}

.name {
  font-family: Rubik;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
}

.address {
  color: #4f5665;
  font-family: Rubik;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.rating {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.content {
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  margin-top: 1.5rem;
}
