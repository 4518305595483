.history-item {
  position: relative;
  padding: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 1rem;
  min-width: 90%;
  width: 90%;
  border-radius: 10px;
  white-space: nowrap;
  /* overflow: hidden; */
  cursor: pointer;
}

.history-item:hover {
  opacity: 0.8;
}

.image {
  height: 4rem;
  width: 4rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-right: 1rem;
}

.title {
  overflow: hidden;
  font-family: Poppins;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  color: black;
  width: 10rem;
  text-overflow: ellipsis;
}

.price,
.description {
  margin: 0;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: rgba(106, 64, 41, 1);
}

.actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 0.5rem;
  z-index: 2;
  transform: translateY(-50%) translateX(25%);
  transition: all ease-in-out 0.4s;
  opacity: 0;
}

.circle,
.circle-x {
  position: relative;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  background-color: rgba(106, 64, 41, 1);
  border-radius: 50%;
}

.circle-x {
  background-color: rgba(255, 186, 51, 1);
}

.action-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.history-item:hover > .actions {
  opacity: 1;
}

@media (min-width: 768px) {
  .history-item {
    min-width: 45%;
    width: 45%;
  }
  .title {
    width: 12rem;
  }
}

@media (min-width: 992px) {
  .history-item {
    min-width: 30%;
    width: 30%;
  }
  .title {
    width: 10rem;
  }
  .circle,
  .circle-x {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .history-item {
    min-width: 30%;
    width: 30%;
  }
  .title {
    width: 14rem;
  }
}

@media (min-width: 1440px) {
  .history-item {
    min-width: 23%;
    width: 23%;
  }
  .title {
    width: 10rem;
  }
}
