.item {
  position: absolute;
  top: 0;
  background-color: #ffcb65;
  width: 15rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
  opacity: 1;
  transform: scale(0.85) translateX(80px);
  transition: all ease-in-out 0.5s;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title,
.discount {
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
}

.discount {
  font-size: 1.15rem;
}

.description {
  font-family: Poppins;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
}

.image {
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  object-position: center;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.coupon {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;
  border-top: 1px dashed black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coupon-text {
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.code {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin: 0.4rem 0;
}

.expirated {
  font-family: Poppins;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
}

.item.active {
  opacity: 1;
  z-index: 1;
  transform: scale(1) translateX(0);
}

.item.next {
  opacity: 1;
  margin-right: -1;
  z-index: 0;
  background-color: black;
  color: white;
}

.item.prev {
  opacity: 0;
  z-index: 0;
  transform: scale(1.2) translateX(100px);
}

.edit {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
