.profile {
  padding: 3rem 1rem;
}

.title {
  font-family: Rubik;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  color: white;
}

.body {
  margin: 1rem 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* justify-content: space-between; */
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .profile {
    padding: 5rem 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    margin: 5rem 0;
  }
}
