.product {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.promo-container {
  width: 100%;
}

.menu {
  width: 100%;
}

@media (min-width: 992px) {
  .product {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 7rem;
  }

  .promo-container {
    width: 40%;
  }
  .menu {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .promo-container {
    width: 33%;
  }
  .menu {
    width: 67%;
  }
}
